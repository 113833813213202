
.companies-container {
    max-width: 2000px;
    margin: auto;
    min-height: 100vh;
    border-bottom:1px solid $cool-grey;
    
}

.companies-header {
    padding: 50px;
    text-align: center;
}

.companies-header-img {
    width: 100%;
    height: auto;
    object-fit: cover;
    max-height: 90vh;
}

.companies-split-text-block {
    @include offset-words-block;
    padding: 50px;
    gap: 50px;
}

.companies-split-txt1, .companies-split-txt1-70{
    @include offset-words-txt1;
    line-height: 30px;
    line-height: normal;
    font-size: 24px;
    width: 100%;
    margin-bottom: 0px;
}

.companies-split-txt2, .companies-split-txt2-80{
    @include offset-words-txt2;
    line-height: normal;
    text-align: right;
    font-size: 18px;
    width: 100%;
    margin-bottom: 0px;
}

.companies-title {
    font-family: $font-1;
    font-size: 36px;
    text-transform: uppercase;
    margin-bottom: 0px;
}

.companies-subheader {
    font-family: $font-2;
    font-size: 14px;
    margin-top: 25px;
    margin-bottom: 0px;
}

.companies {
    .card-white-title {
        font-size: 48px;
        width: 100%;
    }
    .card-white-text {
        padding-bottom: 50px !important;
        width: 100%;
    }

    .card-white-body {
        width: 100%;
        margin: 0px;
        max-width: 500px;
        margin: auto;
    }
    .card-white-img {
        width: 100%;
        margin: auto;
    }
    .card-white-btn{
       margin:0px;
    }
}

.company-logos-img {
    width: 100%;
    height: auto;
    padding: 25px;
}

// desktop styling
@media screen and (min-width: 992px) {
    .companies-card-text {
        margin-top: 25px;
        font-size: 22px;
        line-height: 27px;
    }

    .companies-card-title {
        font-size: 84px;
    }

    .companies-title {
        font-size: 78px;
    }

    .companies-header {
        padding: 100px;
        text-align: left;
    }

    .companies-subheader {
        font-size: 22px;
    }

    .companies-split-text-block {
        padding: 100px;
        gap: 100px;
    }

    .companies {
        .card-white-title {
            font-size: 84px;
        }
        .card-white-text {
            margin-bottom: 0px;
        }
        .card-white-body {
            width: 100%;
            margin: auto 0px;
            max-width: none;
        }
        .card-white-img {
            width: 40.5%;
            margin: auto 0px;
        }
        .card-white-btn{
            margin: 3px;
            --padding1:16px 36px;
            --padding2:19px 39px;
            --margin1:3px ;
            --margin2:0px ;
            animation-duration: .3s;
            animation-name: leavebtn;
            animation-fill-mode: forwards;
            &:hover{
                animation-duration: .3s;
                animation-name: expandbtn;
                animation-fill-mode: forwards;
            }
    }

  
    }
    .company-logos-img {
        padding: 100px;
    }

    .companies-split-txt2-80 {
        width: 80% !important;
        font-size: 22px !important;
    }

    .companies-split-txt1-70 {
        width: 70% !important;
        font-size: 28px;
}
}