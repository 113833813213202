.health-container{
    max-width: 2000px;
    margin: auto;
    min-height:100vh;
    border-bottom:1px solid $cool-grey;
}

.health-hero{
    padding: 100px;
}
.health-header-title{
    @include red-sub-header-text;
}
.health-header-text{
    font-family: $font-1;
    font-size: 78px;
    margin-bottom:0px;
    width: 100%;
}
.health-hero-img{
    padding-bottom: 100px;
    padding-right: 150px;
    width: 100%;
    height: auto;
}
.health-overview-container{
    padding: 100px;
}

.health-overview-body{
//  padding-top: 100px;
 display: flex;
 flex-direction: column;
 gap:100px;
}

.health-overview-card{
    display: flex;
    flex-direction: row;
    gap:150px;
    justify-content: space-between;
}
.health-overview-card-title{
    font-family: $fancy-font;
    font-size: 84px;
}
.health-overview-span{
    border-right:2px solid #A12A2B;
    padding-right: 50px;
}
.health-overview-span2{
    padding-left: 50px;
}
.health-overview-card-text{
    font-size: 22px;
    font-family: $font-2;
    margin-bottom:0px;
}
.health-overview-card-img{
    width:50%;
    height: auto;
    object-fit: cover;
    max-width: 500px;
    min-height: 300px;
}

.health-overview-card-body{
    width:50%;
    padding:25px 0px;
    margin:auto 0px;
}



.health-model-container{
    padding: 100px;
}

.health-model-subtitle{
    font-family: $fancy-font;
    font-size: 84px;
    margin-bottom: 100px;
}

.health-model-body{
    display: flex;
    flex-direction: row;
    gap:100px;
}

.health-model-list{
    width: 56%;
    display: flex;
    flex-direction: column;
    gap:50px;
    font-family: $font-2;
    font-size: 22px;
    margin-bottom:0px;
    padding-left:0px;
    li{
        display: flex;
        flex-direction: row;
    }
}

.health-model-img{
    width: 50%;
    height: auto;
    object-fit: cover;
    min-width: 450px;
    margin: auto 0px;
   
}
// .health-model-img-container{
//     width: 50%;
//     height: auto;
//     object-fit: cover;
//     min-width: 450px;
//     margin: auto 0px;
// }

.health-model-num{
    color: $dark-red;
    white-space: nowrap;
    margin-right: 10px;
}



.health-block-1{
    p{
        font-family: $fancy-font;
        font-size: 84px;
        padding:0px 100px 100px 100px;
        margin-bottom:0px;

    }
}
.health-block-1-img{
    width:100%;
    height: auto;
    padding: 100px;
    padding-top:0px;
    object-fit: cover;
}

.health-card-1{
    padding: 100px;
    padding-bottom: 0px;
    display: flex;
    flex-direction: row;
    gap:100px;
   div{
    margin: auto;
   }
}
.health-card-1-text{
    font-family: $font-2;
    font-size: 22px;
    text-align: right;
    margin-bottom: 0px;
}

.health-card-1-img{
    width:50%;
    height: auto;
}



.health-block-2-img{
    width:100%;
    height: auto;
    padding: 0px 100px;
    object-fit: cover;
}





.health-split-block-1,.health-split-block-2{
    position: relative;
    @include offset-words-block;
}
// .health-split-1a-cover,.health-split-1b-cover{
//     position: absolute;
//     left:0;
//     width: 100%;
//     height:50%;

// }
// .health-split-1a-cover{
//     top: 0;
//     background-image: linear-gradient(90deg,rgba(255, 255, 255, .4) 10%,rgba(255, 255, 255, 1) 15%);
// }
// .health-split-1b-cover{
//     bottom:0;
//     background-image: linear-gradient(90deg,rgb(255, 255, 255) 90%,rgba(255, 255, 255, 0.4) 95%);
// }
.health-split-1a{
    @include offset-words-txt1;
    font-family: $font-1 !important;
    font-size: 28px !important;
    margin-bottom: 0px !important;
}
.health-split-2a{
    @include offset-words-txt1;
    font-family: $font-2 !important;
    font-size: 22px !important;
    margin-bottom: 0px !important;
    max-width: 760px;
}
.health-split-1b{
    @include offset-words-txt2;
    font-family: $font-2 !important;
    font-size: 22px !important;
    margin-bottom: 0px !important;
}
.health-split-2b{
    @include offset-words-txt2;
    font-family: $font-2 !important;
    font-size: 16px !important;
    margin-bottom: 0px !important;
    max-width: 790px;
}

.health-divider-line{
    width:100%;
    height:1px;
    border-top:1px solid $cool-grey;
}

@media screen and (max-width:1200px) {
    .health-header-text{
        font-size: 54px;
        width:85%;
    }

    .health-overview-card{
        gap:50px;
    }

    .health-overview-card-img{
        width:40%;
    }

    .health-overview-card-title{
        font-size: 72px;
    }

    .health-overview-card{
        flex-direction: column;
        gap: 15px;
    }

    .health-overview-body{
        padding-top: 0px;
    }
    
    .health-overview-card-img,.health-overview-card-body{
        width:100%;
        margin: auto;
        max-width: none;
    }

    .health-model-body{
        flex-direction: column-reverse;
        gap:50px;
    }

     .health-model-body{
        flex-direction: column-reverse;
        gap:50px;
    }

    .health-model-list{
        width: 100%;
        gap:15px;
        padding: 0px;
        li{
            display: flex;
            flex-direction: row;
            gap:5px;
        }
    }
    
   
    .health-model-img{
        width: 100%;
        height: auto;
        min-width: 200px;
    }
    .health-model-subtitle{
        margin-bottom: 50px;
        max-width: 1000px;
    }
    .health-card-1{
        padding: 50px;
        gap:50px;
        flex-direction: column-reverse;
    }
    .health-card-1-text{
        font-size: 18px;
        text-align: left;
    }
    
    .health-card-1-img{
        width:100%;
    }
}

// mobile
@media screen and (max-width:760px){
    .health-hero{
        padding: 50px;
    }
    .health-header-title{
        font-size: 18px;
        margin-bottom: 15px;
    }
    .health-header-text{
        font-size: 36px;
        width:100%;
    }
    .health-hero-img{
        padding:0px 50px 25px 0px;
    }
    .health-overview-container{
        padding:50px;
    }

    .health-overview-body{
      padding-top: 0px;
    }
    .health-overview-body,.health-overview-card{
        gap:50px;
    }
    .health-overview-card-body{
        padding: 0px;
    }
    .health-overview-card-title{
        font-size: 48px;
      }
      .health-overview-card-text{
        font-size: 18px;
      }
      .health-overview-span{
        padding-right: 25px;
    }
    .health-overview-span2{
        padding-left: 25px;
    }

    .health-model-container{
        padding: 50px;
    }
  
    .health-model-subtitle{
        font-size: 48px;
    }

    .health-model-list{
        font-size: 14px;
    }
  
    .health-block-1{
        p{
            padding: 0px 30px 50px 30px;
            font-size: 48px;
            text-align: center;
        }
    }
    .health-block-1-img{
        padding:0px 50px 50px 50px;
        min-height: 300px;
    }

.health-split-block-1,.health-split-block-2{
    padding:0px 50px 50px 50px;
    gap:50px;
}
.health-split-1a,.health-split-2a{
  width:100%;
}
.health-split-1b, .health-split-2b{
  text-align: left;
  align-self: flex-start;
  width: 100%;
}
.health-split-1a{
    line-height: 34px;
}
.health-split-1b{
    font-size: 18px !important;
}
.health-split-2a{
    font-size: 18px !important;
}
.health-split-2b{
    font-size: 14px !important;
}
.health-block-2-img{
    padding: 0px 50px 50px 50px;
    min-height: 300px;
}



}