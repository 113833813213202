$black: rgba(0, 0, 0, 1);
$grey-1: rgba(199, 193, 186, 1);
$grey-2: rgba(238, 236, 230, 1);
$terracotta: rgba(216, 131, 102, 1);
$white: rgba(255, 255, 255, 1);
$light-grey:#F7F7F7;
$cool-grey:#D9D9D6;
$cool-grey-opaque:rgba(217,217,214,.3);
$dark-red:#A12A2B;
$off-black:#121212;
$dark-blue:#041C2C;
$gold:#aa8c2c;
@font-face {
    font-family: "NeueHelvetica45Light";
    src: url('../fonts/NeueHelvetica45Light/font.woff2') format('woff2'), url('../fonts/NeueHelvetica45Light/font.woff') format('woff');
    font-display: swap;
  };
@font-face {
    font-family: "MrSheffieldProRegular";
    src: url('../fonts/MrSheffieldProRegular/font.woff2') format('woff2'), url('../fonts/MrSheffieldProRegular/font.woff') format('woff');
    font-display: swap;
  };
  @font-face {
    font-family: "FreightBigProBook";
    src: url('../fonts/FreightBigProBook/font.woff2') format('woff2'), url('../fonts/FreightBigProBook/font.woff') format('woff');
    font-display: swap;
  };
  @font-face {
    font-family: "NeueHelvetica55Roman";
    src: url('../fonts/NeueHelvetica55Roman/font.woff2') format('woff2'), url('../fonts/NeueHelvetica55Roman/font.woff') format('woff');
    font-display: swap;
  };
$font-1:"FreightBigProBook","Times New Roman", serif;
$font-2: 'NeueHelvetica45Light', Helvetica, sans-serif;
$font-3: 'NeueHelvetica55Roman', Helvetica, sans-serif;
$fancy-font:'MrSheffieldProRegular',"Brush Script MT", "cursive";

@keyframes navslide {
    from {
      opacity: 100%;
    }
    to {
      opacity: 0%;
      display: none;
    }
  }

@keyframes expandbtn{
    from{
        padding: var(--padding1);
        margin:var(--margin1);
    }
    to{
        padding: var(--padding2);
        margin:var(--margin2);
    }
}
@keyframes leavebtn{
    from{
        padding: var(--padding2);
        margin:var(--margin2);
    }
    to{
        padding: var(--padding1);
        margin:var(--margin1);
    }
}


@keyframes navhover{
    0%{
        background-color:var(--color1);
    }
    50%{
        background-color:var(--color2);
    }
    100%{
        background-color:var(--color3);
    }
  } 
@keyframes hovercolor{
    from{
        color:var(--color1);
    }
    to{
        color:var(--color2);
    }
  }

  @keyframes hoverunderline{
    0%{
        border-bottom:  1px solid #041C2C;
    }
    50%{
        border-bottom: 1px solid rgb(134, 134, 134);
    }
    75%{
        border-bottom: 1px solid rgb(177, 177, 177);
    }
    100%{
        border-bottom: 1px solid white;
    }
  }
  
  @keyframes leaveunderline{
    0%{
        border-bottom: 1px solid white;
    }
    50%{
        border-bottom: 1px solid rgb(117, 117, 117);
    }
    100%{
        border-bottom: 1px solid #041C2C;
    }
}

  @keyframes cardexpand{
    from{
        width:100%;
        margin-bottom:var(--marBotStart);
    }
    to{
        width:101%;
        margin-bottom:var(--marBotEnd);
    }
  }

  @keyframes arrowexpand{
    from{
        margin:var(--margin1);
        font-size:var(--size1);
    }
    to{
        margin:var(--margin2);
        font-size:var(--size2);
    }
  }

  @keyframes cardcontract{
    from{
        width:100%;
        margin-bottom:var(--marBotStart);
        margin-left: 0px;
        margin-right: 0px;
        height:448.87px;
    }
    to{
        width:99%;
        margin-bottom:var(--marBotEnd);
        margin-left: 1px;
        margin-right: 1px;
        height:446px;
    }
  }

@mixin dark-red-btn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 16px 36px;
    gap: 10px;
    width:fit-content;
    border: none;
    background: $dark-red;
    font-family: $font-3;
    font-style: normal;
    font-size: 14px;
    letter-spacing: 0.15em;
    text-transform: uppercase;
    margin:3px;
    color: #FFFFFF;
};
@mixin dark-red-outline-btn {
    @include dark-red-btn;
    border: 1px solid $dark-red;
    background: transparent;
    color: $dark-red;
    // &:hover{
    //     color: white;
    //     background-color: $dark-red;
    // }
};
@mixin offset-words-block {
    display: flex;
    flex-direction: column;
    gap:100px;
    padding: 100px;
}
@mixin offset-words-txt1{
    width:80%;
    align-self: flex-start;
    color:$off-black;
    font-family: $font-1;
    font-weight: 400;
    font-size: 28px;
}
@mixin offset-words-txt2{
    align-self: flex-end;
    color:$off-black;
    font-family: $font-2;
    width:80%;
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    // text-align: right;
}

@mixin red-sub-header-text{
    font-family: $font-3;
    font-size: 18px;
    text-transform: uppercase;
    color: $dark-red;
    margin-bottom: 0px;
    letter-spacing: .15em;
}

@mixin companies-card{
    display: flex;
    flex-direction: row;
    background-color: white;
    gap: 50px;
    padding:50px;

}
@mixin companies-card-text{
        font-family: $font-2;
        font-size: 22px;
        line-height: 26px;
        display: flex;
        flex-direction: row;
        margin:auto;
        gap:25px;
    
}
@mixin companies-card-img{
    height: auto;
    object-fit: cover;
    max-height:none;
        width: 50%;

}

.dark-red-btn{
    @include dark-red-btn;
    &:hover{
        color:white;
    }
}

