// BELOW IS FOR DESKTOP VIEW
.team-container {
  max-width: 2000px;
  margin: auto;
  min-height:100vh;
  border-bottom:1px solid $cool-grey;
}

.team-hero-subhead {
  @include red-sub-header-text;
}

.team-hero-title {
  font-family: $font-1;
  font-size: 78px;
}

.team-hero-text {
  font-size: 16px;
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.team-hero-img {
  width: 100%;
  object-fit: cover;
  margin-bottom: 100px;
  padding-right: 150px;
}

.team-hero {
  padding: 100px;
}

.team-offset-text-block {
  @include offset-words-block;
}

.team-offset-text {
  @include offset-words-txt2;
  font-family: $font-1;
  font-size: 36px;
  text-align: right;
  width: 95%;
}

.team-headshots {
  padding: 100px;
}

.company-tabs {
  display: flex;
  flex-direction: row;
  position: relative;
  font-family: $font-3;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 0.15em;
  margin: auto;
  margin-bottom: 50px;
  max-width: 1239px;
  p {
    z-index: 2;
    padding: 16px 32px;
    border: none;
    &:hover {
      --color1:black;
      --color2:#A12A2B;
      animation-duration: .2s;
      animation-name: hovercolor;
      animation-fill-mode: forwards;
    }
    &:active, &:focus {
      border-bottom: 2px solid $dark-red;
      color:$dark-red;
    }
  }
}

.tabs-underline {
  position: absolute;
  width: 100%;
  height: 1px;
  border-top: 1px solid $cool-grey;
  top: 52.5px;
  z-index: -1;
}

.team-cards-container{
  display:flex;
  align-items: center;
  justify-content: center;
}

.team-cards {
  display: grid;
  grid-template-columns: repeat(auto-fill, 397px);
  grid-gap: 24px;
  justify-content: center;
  align-content: flex-start;
  list-style: none;
  width: 100%;
  height: 100%;
  margin: 0px auto;
  padding: 0;
}

.team-card {
  margin-bottom: 25px;
  justify-content: center;
  align-self: flex-start;
  width:397px;
}

.join-team-card {
  background-color: $dark-blue;
  margin-bottom: 100px;
  width: 100%;
  max-width: 397px;
  min-width: 200px;
  height: 448.87px;
  color: white;
  font-size: 36px;
  font-family: $font-1;
  display: flex;
  flex-direction: column;
  padding: 0px 80px;
  &:hover {
    --marBotStart:100px;
    --marBotEnd:100px;
    animation-name: cardcontract;
    animation-duration: .3s;
    animation-fill-mode: forwards;
  }
  p {
    margin: auto 0px;
  }
}

.team-card-img {
  margin-bottom: 25px;
  width: 100%;
  max-width: 397px;
  min-width: 200px;
  height: auto;

}

.team-card-name {
  font-family: $font-1;
  font-size: 28px;
  line-height: 30px;
  margin-bottom: 5px;
}

.team-card-title {
  font-family: $font-3;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.7px;
  text-transform: uppercase;
  margin-bottom: 0px;
}

.ceo-bio {
  padding: 100px;
  border-top: 1px solid $cool-grey;
  border-bottom: 1px solid $cool-grey;
  margin-bottom: 0px;
}

.ceo-bio-body {
  display: flex;
  flex-direction: row-reverse;
  margin-bottom: 0px;
  gap: 100px;
}

.ceo-bio-text {
  font-family: $font-2;
  line-height: 20px;
  margin-bottom: 0px;
  display: flex;
  flex-direction: column;
  gap: 25px;
  margin: auto;
  font-size: 16px;
  max-width: 1240px;
}

.ceo-bio-sub {
  @include red-sub-header-text;
  margin-bottom: 100px;
}

.ceo-bio-img {
  width: 50%;
  height: auto;
  margin: auto;
}

.ceo-bio-name {
  font-family: $font-1;
  font-size: 48px;
}

.ceo-bio-blurb {
  font-family: $font-3;
  font-size: 14px;
  text-transform: uppercase;
  margin-bottom: 0px;
  letter-spacing: 0.05em;
}

.team-active-tab{
  cursor: pointer;
  &:active, &:focus {
    border-bottom: 2px solid $dark-red;
    color:$dark-red;
  }
}
@media screen and (max-width: 1190px) {
  .company-tabs {
    margin: auto auto 50px auto;
    justify-content: space-between;
    gap: 0px;
  }
  .ceo-bio-body{
    gap:50px;
  }
}

// BELOW IS FOR MOBILE VIEW
@media screen and (max-width: 900px) {
  .team-hero {
    padding: 50px;
  }

  .team-hero-title {
    font-size: 28px;
  }

  .team-hero-subhead{
    font-size: 14px;
  }
  .team-hero-text {
    font-size: 14px;
  }



  .team-card-img {
    width:100%;
  }

  .team-card {
    margin:50px 0px 0px 0px;
    width:fit-content;
    max-width:none;
    align-self: center;
    &:first-of-type {
      margin-top: 50px;
    }
  }
  .join-team-card{
    width:100%;
    max-width: 397px;
    margin: auto;

  }
  .team-cards{
   display: flex;
   flex-direction: column;
   grid-gap:0px;
   align-content: center;
    
  }
  
  .team-hero-img {
    padding-right: 50px;
    margin-bottom: 50px;
    min-height: 220px;
    margin-bottom: 25px;
  }

  .team-offset-text-block {
    padding: 50px;
  }

  .team-offset-text {
    font-size: 18px;
    line-height: normal;
    width: 99%;
    text-align: right;
    margin-bottom: 0px;
  }

  .team-headshots {
    padding: 50px;
  }
  .dropdown-container{
    width:100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  .react-select-container {
    display: flex;
    flex-direction: row;
    margin: auto;
    justify-content: flex-end;
    width: 100%;
    max-width: 500px;
    min-width: 200px;
  }

  .react-select__control {
    width: 40vw;
    font-family: $font-3 !important;
    text-transform: uppercase !important;
    padding: 8px 0px !important;
    &:focus {
      outline: none;
    }
  }

  .react-select__menu {
    font-family: $font-3 !important;
    letter-spacing: .7px;
    text-transform: uppercase !important;
    margin-top: 0px;
  }

 
  .react-select__indicator-separator {
    display: none;
  }

  .react-select__indicator {
    color: $off-black !important;
  }

  .react-select__placeholder {
    color: $off-black !important;
  }

  .react-select__input {
    font-family: $font-3 !important;
    text-transform: uppercase !important;
  }



  .react-select__option {
    &:active {
      background: transparent;
      background-color: transparent;
    }
  }

  .react-select__option--is-focused {
      background-color: $light-grey !important;
      color: $dark-red !important;
  }

  .ceo-bio {
    padding: 50px;
  }

  .ceo-bio-body {
    flex-direction: column;
    gap: 50px;
  }

  .ceo-bio-sub {
    font-size: 14px;
    margin-bottom: 50px;
  }

  .ceo-bio-img {
    width: 100%;
    max-width: 500px;
    max-height: none;
    margin: auto;
  }

  .ceo-bio-name {
    font-family: font-1;
    font-size: 36px;
  }
  
  .ceo-bio-text {
    gap: 15px;
    font-size: 18px;
  }
}
