// below is for desktop

// corso hero

.corso-container {
  max-width: 2000px;
  margin: auto;
  min-height:100vh;
}

.corso-hero {
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  padding: 100px;
  gap: 25px;
}

.dark-red-btn.corso-our-website-btn {
  width: fit-content !important;
  margin: 3px;
  --padding1:16px 36px;
  --padding2:19px 39px;
  --margin1:3px ;
  --margin2:0px ;
  animation-duration: .3s;
  animation-name: leavebtn;
  animation-fill-mode: forwards;
  &:hover{
    animation-duration: .3s;
    animation-name: expandbtn;
    animation-fill-mode: forwards;
  }
}

.corso-hero-sub {
  @include red-sub-header-text;
}

.corso-hero-title {
  font-family: $font-1;
  max-width: 900px;
  font-size: 78px;
  text-transform: capitalize;
  color: $off-black;
}

// corso quote
.corso-quote-container {
  padding: 100px;
  display: flex;
  flex-direction: column;
}

.corso-quote {
  font-family: $font-1;
  font-size: 48px;
  text-align: right;
  color: $off-black;
  width: 88%;
  align-self: flex-end;
  margin-bottom: 0px;
}

.corso-quote-sub {
  font-family: $font-3;
  font-size: 18px;
  text-align: right;
  text-transform: uppercase;
  color: $off-black;
  margin-top: 15px;
  letter-spacing: .15em;
}

// image divider1

.corso-img-1 {
  width: 100%;
  height: auto;
}

//mission statement
.mission-txt1 {
  line-height: 30px;
  font-family: $font-2;
}

.mission-txt2 {
  line-height: 26px;
  font-family: $font-2;
}

.mission-statement-block,
.text-block1-block,
.text-block2-block,
.text-block3-block,
.corso-offset-block {
  @include offset-words-block;
  margin-bottom: 0px;
}

.mission-txt1,
.text-block1-txt1,
.text-block2-txt1,
.text-block3-txt1,
.corso-offset-text-1 {
  @include offset-words-txt1;
  margin-bottom: 0px;
}

.mission-txt2,
.text-block1-txt2,
.text-block2-txt2,
.text-block3-txt2,
.corso-offset-text-2 {
  @include offset-words-txt2;
  margin-bottom: 0px;
}

.text-block1-txt1,
.text-block2-txt1,
.text-block3-txt1 {
  font-family: $font-2;
  // font-size: 18px;
  font-size: 22px;
}

.text-block1-txt2,
.text-block2-txt2,
.text-block3-txt2 {
  // font-size: 14px;
  font-size: 16px;
}

.corso-partner-btn{
  margin:3px auto;
  --padding1:16px 36px;
  --padding2:19px 39px;
  --margin1:3px auto;
  --margin2:0px auto;
  animation-duration: .3s;
  animation-name: leavebtn;
  animation-fill-mode: forwards;
  &:hover{
    color:white;
    animation-duration: .3s;
    animation-name: expandbtn;
    animation-fill-mode: forwards;
}
}
.download-brochure-btn {
  align-self: center;
  @include dark-red-btn;
  min-width: fit-content;
  white-space: nowrap;
  --padding1:16px 36px;
  --padding2:19px 39px;
  --margin1:3px auto;
  --margin2:0px auto;
  animation-duration: .3s;
  animation-name: leavebtn;
  animation-fill-mode: forwards;
  &:hover{
    animation-duration: .3s;
    animation-name: expandbtn;
    animation-fill-mode: forwards;
}
}

.corso-offset-text-2,
.text-block2-txt2 {
  width: 70%;
}

//

.corso-img-div {
  margin-bottom: 50px;
  display: flex;

  overflow: hidden;
  flex-direction: row;
  height: auto;
  gap: 50px;
  img {
    width: 50%;
    object-fit: cover;
  }
}

//

.corso-img-2 {
  width: 100%;
  height: auto;
  object-fit: cover;
}

//

.corso-img-landscape-div {
  display: flex;
  overflow: hidden;
  flex-direction: row;
  height: auto;
  gap: 50px;
  img {
    width: 50%;
    object-fit: cover;
  }
}

.corso-block-5-img,
.corso-block-4-img {
  width: 100%;
  height: auto;
}

.corso-card.card-grey {
  gap: 100px !important;
}

.corso-card {
  margin-bottom: 0px;
  .card-grey-img {
    width: 50%;
    height: auto;
  }
  .card-grey-body {
    gap: 25px;
  }
  .card-grey-body-title {
    font-size: 54px;
    margin-bottom: 0px;
  }
  .card-grey-body-text {
    font-size: 22px;
    line-height: normal;
    margin-bottom: 0px;
    padding-bottom: 25px;
  }
  // .card-grey-button {
  //   margin-top: 25px !important;
  // }
}

.corso-testimonial-block {
  width: 100%;
  height: auto;
}

.corso-locations-card-container {
  display: flex;
  flex-direction: row;
  margin: auto;
  gap: 50px;
}

.corso-locations-card {
  margin: auto;
  font-family: $font-3;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  width: 100%;
  img {
    width: 100%;
    height: auto;
    margin-bottom: 38px;
    object-fit: cover;
    &:hover {
      --marBotStart:38px;
      --marBotEnd:35px;
      animation-name: cardexpand;
      animation-duration: .3s;
      animation-fill-mode: forwards;
    }
  }
}

.corso-locations-container {
  padding: 100px 50px 50px 50px;
  border-bottom: 0.5px solid $cool-grey-opaque;
  h1 {
    font-family: $font-3;
    font-size: 18px;
    color: $dark-red;
    text-transform: uppercase;
    margin-bottom: 50px;
    letter-spacing: 0.05em;
  }
}


.corso-testimonial-block {
  .testimonial-quote {
    font-size: 36px !important;
  }

  .testimonial-container {
    padding: 50px 125px !important;
  }

  .testimonial-card {
    gap: 0px;
  }

  .prev-arrow,.prev-arrow-disabled{
    top: 50% !important;
    left: 3% !important;
  }


  .next-arrow {
    top: 50% !important;
    right: 3%;
  }
}

// below for ipad
@media screen and (max-width: 1080px) {
  .corso-card.card-grey{
    gap: 50px !important;
  }
  .corso-card {
    .card-grey-body {
      gap: 15px !important;
    }
  }
  .corso-locations-card{
    p{
      height:40px;
    }
  }
  .contact-collection-card-text{
    display: flex;
    flex-direction: row;
    gap:10px;
  }
  .corso-testimonial-block {
    .testimonial-container {
      padding:50px 75px !important;
    }
    .testimonial-body{
      padding-right: 0px;
    }
    .testimonial-quote {
      font-size: 28px !important;
    }
    .testimonial-name{
      font-size: 14px;
    }
  }
}

// BELOW IS FOR MOBILE
@media screen and (max-width: 760px) {
  .corso-hero {
    padding: 50px;
    min-height: none;
  }

  .corso-hero-sub {
    font-size: 14px;
  }

  .corso-hero-title {
    font-size: 28px;
    margin-bottom: 0px;
  }

  .corso-quote-container {
    padding: 50px;
  }

  .corso-quote {
    font-size: 18px;
  }

  .corso-quote-sub {
    font-size: 10px;
    margin-top: 15px;
  }

  .mission-txt2 {
    font-size: 18px;
    align-self: flex-start;
    text-align: left;
    width: 100%;
  }

  .mission-txt1 {
    width: 100%;
  }

  .mission-statement-block {
    padding: 50px;
    gap: 50px;
  }

  .corso-card {
    .card-grey-img {
      width: 100%;
      max-height: 70vh;
    }
    .card-grey {
      gap: 50px;
    }
    .card-grey-button {
      width: 100%;
    }
    .card-grey-body-text {
      font-size: 14px;
    }
    .card-grey-body-title {
      font-size: 48px;
    }
  }

  .corso-card.card-grey {
    gap: 50px !important;
  }

  .text-block1-block,
  .text-block2-block,
  .text-block3-block,
  .corso-offset-block {
    padding: 50px;
    gap: 50px;
  }

  .text-block1-block {
    margin-top: 50px;
  }

  .text-block1-block {
    padding-top: 0px;
  }

  .corso-offset-text-1 {
    font-size: 28px;
    line-height: 2rem;
  }

  .text-block1-txt1,
  .text-block2-txt1,
  .text-block3-txt1,
  .corso-offset-text-2 {
    font-size: 18px;
    line-height: 21.76px;
  }

  .text-block1-txt2,
  .text-block2-txt2,
  .text-block3-txt2 {
    font-size: 14px;
    line-height: 16.93px;
  }

  .text-block1-txt2,
  .text-block2-txt2,
  .text-block3-txt2,
  .text-block1-txt1,
  .text-block2-txt1,
  .text-block3-txt1,
  .corso-offset-text-2,
  .corso-offset-text-1 {
    width: 100%;
    align-items: flex-start;
    text-align: left;
  }

  .corso-img-div {
    margin-bottom: 25px;
  }

  .corso-img-div,
  .corso-img-landscape-div {
    gap: 25px;
  }

  .corso-locations-card-container {
    flex-direction: column;
    gap: 50px;
  }

  .corso-locations-container {
    padding: 50px;
  }

  .corso-locations-card {
    img {
      margin-bottom: 25px;
      &:hover {
        animation-name:none;
        margin-bottom: 25px;
      }
    }
  }

  .corso-testimonial-block {
    .testimonial-quote {
      font-size: 28px !important;
    }
    .testimonial-body {
      padding: 25px 0px 0px 0px !important;
      gap: 25px !important;
    }
    .testimonial-container {
      padding: 50px 75px !important;
    }
    .prev-arrow {
      left: 5%;
    }
    .next-arrow {
      right: 5%;
    }
  }

  .corso-partner-btn,.dark-red-btn.corso-our-website-btn, .download-brochure-btn{
    &:hover{
      animation-name:none;
  }
  }

  .dark-red-btn.corso-our-website-btn {
    margin: 0px !important;
   
  }
}
