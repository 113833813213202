// card-grey grey

.card-grey {
    display: flex;
    // flex-direction: row; THIS IS SPECIFIED INLINE IN VPcard-grey.JS
    padding: 50px;
    gap: 50px;
    background-color: $light-grey;
}

.card-grey-body {
    display: flex;
    flex-direction: column;
    gap: 50px;
    justify-content: center;
}

.card-grey-body-text {
    font-family: $font-2;
    font-size: 16px;
    line-height: 19px;
}

.card-grey-body-title {
    font-family: $fancy-font;
    font-size: 84px;
}

.card-grey-img {
    width: 100%;
    height: auto;
    object-fit: cover;
}

// card white
.card-white-body {
    width: 100%;
    max-width: 500px;
    margin: auto 0px;
}

.card-white {
    padding: 50px;
    display: flex;
    flex-direction: column;
    gap: 25px;
    width: 100%;
    height: auto;
    max-width: 800px;
    margin: 0px auto;
}

.card-white-title {
    font-family: $fancy-font;
    font-size: 28px;
    color: $off-black;
    margin: 25px 0px;
}

.card-white-text {
    font-family: $font-2;
    font-size: 14px;
    color: $off-black;
    line-height: 17px;
}

.card-white-img {
    width: 100%;
    height: auto;
    max-width: 500px;
    object-fit: cover;
    margin: auto;
    max-height: 600px;
    object-position: center bottom;
}

.card-white-btn {
    @include dark-red-btn;
    margin-top: 25px;
    &:hover {
        color: white;
    }
}

@media screen and (min-width: 992px) {
    // card white
    .card-white {
        padding: 100px;
        gap: 100px;
        max-width: none;
        margin: 0px auto;
    }

    .card-white-img {
        max-width: 800px;
        max-height: 1144px;
        object-fit: cover;
        width: 45%;
    }

    .card-white-body {
        margin: auto;
        width: 55%;
        background-color: white;
    }

    .card-white-btn {
        margin-top: 50px;
    }

    .card-white-text {
        margin-top: 25px;
        font-size: 22px;
        line-height: 27px;
    }

    .card-white-title {
        font-size: 84px;
    }

    // card grey

    .card-grey-button {
        width: fit-content;
        margin: 3px ;
        --padding1:16px 36px;
        --padding2:19px 39px;
        --margin1:3px ;
        --margin2:0px ;
        animation-duration: .3s;
        animation-name: leavebtn;
        animation-fill-mode: forwards;
        &:hover{
            color:white;
            animation-duration: .3s;
            animation-name: expandbtn;
            animation-fill-mode: forwards;
        }
    
    }

    .card-grey {
        padding: 100px;
        gap: 50px;
    }

    .card-grey-body {
        gap: 25px;
    }

    .card-grey-body-title {
        font-size: 48px;
    }

    .card-grey-img {
        width: 100%;
        height: auto;
        object-fit: cover;
    }
}
