.press-container {
  padding: 25px;
  border-top: 1px solid $cool-grey;
  max-width: 2000px;
  margin: auto;
  min-height:100vh;
  border-bottom:1px solid $cool-grey;
}

.press-hero {
  padding-bottom: 50px;
}

.press-hero-text {
  font-family: $font-1;
  width: 300px;
  font-weight: 400;
  font-size: 36px;
  line-height: 45px;
  text-align: center;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  margin: auto;
}

.featured-articles-container,
.all-articles-container {
  padding: 25px;
  border: 1px solid $cool-grey;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  min-height: 30vh;
 
}

.featured-img {
  width: 100%;
  margin-bottom: 25px;
  img {
    width: 100%;
    height: auto;
    object-fit: cover;
    max-height: 600px;
  }
 
}

.featured-body,
.all-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-self: stretch;
  min-height: 300px;
  gap: 25px;
}

.featured-title,
.all-title {
  font-family: $font-1;
  font-size: 28px;
  color: $off-black;
  margin-left: 5px;
  text-transform: capitalize;
}

.featured-date-and-url,
.all-date-and-url {
  display: flex;
  flex-direction: row;
  border-top: 1px solid $cool-grey;
  border-bottom: 1px solid $cool-grey;
  width: 100%;
  // height: auto;
}

.featured-date,
.all-date {
  flex: 8;
  font-family: $font-3;
  font-size: 14px;
  text-transform: uppercase;
  color: $off-black;
  padding: 12px;
  margin: auto;
  letter-spacing: 0.07rem;
  overflow-wrap: break-word;
}

.featured-url,
.all-url {
  flex: 1;
  font-family: $font-3;
  border-left: 1px solid $cool-grey;
  padding: 12px;
  text-align: center;
  margin-bottom:0px;
  letter-spacing: 0.08rem;
}

.featured-text,
.all-text {
  margin-bottom: 25px;
  font-family: $font-2;
  font-size: 14px;
  color: $off-black;
  line-height: normal;
}

.featured-btn,
.all-btn {
  @include dark-red-outline-btn;
  width: 100%;
  text-decoration: none !important;
  &:hover{
    color: $dark-red;
  }
}

.press-inquiries-container {
  padding: 25px;
  display: flex;
  flex-direction: column;
  gap: 25px;
  border-left: 1px solid $cool-grey;
  border-right: 1px solid $cool-grey;
}

.press-inquiries-title {
  font-family: $font-1;
  font-size: 28px;
  text-align: center;
  margin-bottom: 0px;
  color: $off-black;
}

.press-inquiries-text {
  font-family: $font-2;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  padding-bottom: 25px;
  margin-bottom: 0px;
}

.press-inquiries-btn {
  @include dark-red-btn;
  width: 100%;
  max-width: 350px;
  margin: auto;
}

@media screen and (max-width: 1196px) and (min-width: 935px) {
  .press-container {
    padding: 5vw !important;
  }

  .featured-img {
    border-right: 1px solid $cool-grey;
    padding: 40px;
    img {
      margin: auto;
      height: 100%;
      min-width:385px;
  
    }
  }
}

@media screen and (min-width: 935px) {
  .press-container {
    padding: 100px;
  }

  .all-body {
    min-height: 300px;
    padding: 40px;
  }

  .press-hero {
    padding-bottom: 50px;
  }

  .featured-title,
  .all-title {
    font-size: 36px;
    margin-left: 0px;
  }

  .featured-date,
  .featured-url,
  .all-date,
  .all-url {
    font-size: 14px;
    text-transform: uppercase;
    color: #121212;
    &:hover {
      cursor: pointer;
    }
  }

  .press-hero-text {
    width: 100%;
    font-weight: 400;
    font-size: 68px;
    line-height: 73px;
  }

  .featured-articles-container {
    flex-direction: row;
    gap: 0px;
    padding: 0px;
  }

  .featured-text,
  .all-text {
    font-size: 16px;
    line-height: 19px;
  }

  .all-articles-container {
    gap: 0px;
    width: 50%;
    padding: 0px;
  }

  .press-all-articles {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .featured-img {
    width: 50%;
    flex: 1;
    display: flex;
    justify-content: center;
    border-right: 1px solid $cool-grey;
    padding: 40px;
    margin-bottom: 0px;
    min-width:385px;
  
    img {
      max-height: none;
    }
  }

  .featured-body {
    flex: 1;
    padding: 40px;
  }

  .featured-btn,
  .all-btn {
    width: fit-content;
    margin:3px;
    --padding1:16px 36px;
    --padding2:19px 39px;
    --margin1:3px ;
    --margin2:0px ;
    animation-duration: .3s;
    animation-name: leavebtn;
    animation-fill-mode: forwards;
    &:hover{
      animation-duration: .3s;
      animation-name: expandbtn;
      animation-fill-mode: forwards;
    }
  }

  .press-inquiries-btn {
    max-width: fit-content;
    margin: auto;
    margin:3px auto;
    --padding1:16px 36px;
    --padding2:19px 39px;
    --margin1:3px auto;
    --margin2:0px auto;
    animation-duration: .3s;
    animation-name: leavebtn;
    animation-fill-mode: forwards;
    &:hover{
      animation-duration: .3s;
      animation-name: expandbtn;
      animation-fill-mode: forwards;
    }
  }

  .press-inquiries-container {
    padding: 100px;
    gap: 15px;
  }

  .press-inquiries-title {
    font-size: 48px;
  }
  
  .press-inquiries-text {
    font-size: 16px;
    padding-bottom: 35px;
  }
}
