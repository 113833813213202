.careers-container{
   max-width: 2000px;
   margin: auto;
   min-height: 100vh;
   border-bottom:1px solid $cool-grey;
 }

.careers-hero {
   padding: 50px;
   display: flex;
   flex-direction: column;
   gap: 15px;
}

.careers-hero-sub {
   @include red-sub-header-text;
   font-size: 14px;
   margin-bottom: 0px;
}

.careers-hero-title {
   font-family: $font-1;
   font-size: 28px;
   color: $off-black;
   margin-bottom: 0px;
}

.careers-blurb-div {
   @include offset-words-block;
   border-bottom: 1px solid $cool-grey;
   padding: 50px;
}

.careers-blurb-text {
   @include offset-words-txt2;
   font-family: $font-1;
   font-size: 18px;
   width: 95%;
   margin-bottom: 0px;
   max-width: 800px;
   text-align: right;
   line-height: normal;
}

.careers-img {
   width: 100%;
   padding-right: 50px;
   padding-bottom: 25px;
   height: auto;
   object-fit: cover;
   min-height: 230px;
}

.open-positions-container {
   margin: 0px 50px;
}

.open-positions-div {
   display: flex;
   flex-direction: column;
   width: 100%;
   padding: 50px 0px;
   justify-content: space-between;
   align-items: baseline;
   border-bottom: 1px solid $cool-grey;
   // max-width: 500px;
   margin: auto;
   &:last-child{
      border-bottom: none;
   }
}

.careers-open-positions-header {
   padding: 50px;
}

.careers-open-positions-header-title {
   font-family: $font-1;
   font-size: 36px;
   width: 100%;
   max-width: 400px;
   margin: auto;
}

.no-positions-text {
   font-family: $font-2;
   font-size: 16px;
   color: $off-black;
}

.careers-employer-div {
   font-family: $fancy-font;
   font-size: 54px;
   color: $dark-red;
   padding-bottom: 50px;
   margin-right:25px;
}

.careers-map-container{
   width: 100%;
}

.careers-employer {
   font-size: 54px !important;
   margin-bottom: 0px;
}

.job-entries {
   display: flex;
   flex-direction: column;
   padding-bottom: 50px;
   gap: 25px;
   width: 100%;
   &:last-child{
      padding-bottom: 0px;
   }
}

.careers-position-div {
   font-family: $font-2;
   font-size: 22px;
   color: $off-black;
   text-transform: capitalize;
   line-height: normal;
}

.careers-hours-title {
   font-family: $font-3;
   font-size: 14px;
   text-transform: uppercase;
   color: $dark-red;
   padding: 5px 0px;
   letter-spacing: .15em;
   margin-bottom: 0px;
}

.careers-hours-text {
   font-family: $font-2;
   font-size: 14px;
   color: $off-black;
   margin-bottom: 0px;
   text-transform: capitalize;
}

.careers-location-title {
   font-family: $font-3;
   font-size: 14px;
   text-transform: uppercase;
   color: $dark-red;
   padding: 5px 0px;
   letter-spacing: .15em;
   margin-bottom: 0px;
}

.careers-location-text {
   font-family: $font-2;
   font-size: 14px;
   color: $off-black;
   margin-bottom: 0px;
   text-transform: capitalize;
}

.view-job-btn {
   @include dark-red-outline-btn;
   margin: auto;
   width: 100%;
}

@media screen and (min-width: 900px) {

   .careers-hero-sub {
      @include red-sub-header-text;
   }

   .careers-hero-title {
      font-size: 78px;
   }

   .careers-hero {
      padding: 100px;
      gap: 25px;
   }

   .careers-blurb-text {
      font-size: 36px;
      width: 60%;
   }

   .careers-blurb-div {
      padding: 100px;
   }

   .open-positions-container {
      margin: 0px 100px;
   }

   .open-positions-div {
      flex-direction: row;
      padding: 50px 0px;
     padding-left:0px;
   }

   .careers-open-positions-header-title {
      font-size: 48px;
      max-width: none;
   }

   .careers-open-positions-header {
      padding: 100px;
      padding-bottom: 50px;
   }

   .careers-employer-div {
      width: fit-content;
      padding: 0px 15px;
      .careers-employer {
         min-width: 239px;
         font-size: 54px !important;
         margin-bottom: 0px;
      }
   }
   
   .careers-map-container {
      width: 75%;
      align-self: flex-end;
      margin-top: 10px;
   }
   
   .no-positions-text {
      margin-top: -35px;
      margin-bottom: 0px;
   }
   
   .job-entries {
      display: grid;
      grid-template-columns: 4fr 2fr 2fr 1fr;
      grid-template-rows: 1fr;
      gap: 0px 20px;
      grid-auto-flow: row;
      grid-template-areas: "careers-position-div careers-hours-div careers-location-div see-position-btn";
      width: 100%;
      margin-bottom: 50px;
      padding-bottom: 0px;
      :first-of-type {
         padding-top: 5px;
      }
   }

   .careers-position-div {
      grid-area: careers-position-div;
      font-size: 16px;
   }

   .careers-hours-div {
      grid-area: careers-hours-div;
      .careers-hours-title {
         padding: 5px 0px;
      }
   }

   .careers-location-div {
      grid-area: careers-location-div;
      .careers-location-title {
         padding: 5px 0px;
      }
   }

   .see-position-btn {
      grid-area: see-position-btn;
      align-content: baseline;
   }

   .see-position-arrow-icon{
      font-size: 25px;
      &:hover{
         --size1:25px;
         --size2:28px;
         animation-duration: .3s;
         animation-name: arrowexpand;
         animation-fill-mode: forwards;
      }
      
   }

   .careers-img {
      max-width: 2500px;
      padding-right: 150px;
      padding-bottom: 100px;
   }
}

// ipad
@media screen and (min-width: 900px) and (max-width: 1100px) {
 
   .careers-img{
      padding-bottom:0px;
   }

   .careers-blurb-text{
      width:80%;
   }
}