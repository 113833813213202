.vp-container {
  max-width: 2000px;
  margin: auto;
  min-height:100vh;
}

.vp-hero {
  display: flex;
  flex-direction: column;
  padding: 100px;
  gap: 25px;
}

.vp-hero-sub {
  @include red-sub-header-text;
  font-size: 18px;
}

.vp-hero-title {
  font-family: $font-1;
  font-size: 78px;
  text-transform: capitalize;
  width: 90%;
}

.dark-red-btn.vp-our-website-btn {
  width: fit-content !important;
  margin: 3px;
  --padding1:16px 36px;
  --padding2:19px 39px;
  --margin1:3px ;
  --margin2:0px ;
  animation-duration: .3s;
  animation-name: leavebtn;
  animation-fill-mode: forwards;
  &:hover{
    animation-duration: .3s;
    animation-name: expandbtn;
    animation-fill-mode: forwards;
  }
}

.vp-learn-more-btn {
  @include dark-red-btn;
  align-self: center;
  margin: auto;
  margin-bottom: 50px;
}

.vp-quote {
  @include offset-words-block;
  gap: 25px;
}

.vp-quote-subhead {
  @include offset-words-txt2;
  font-family: $font-3;
  font-size: 18px;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  width: fit-content;
  text-align: right;
  margin-bottom: 0px;
}

.vp-quote-text {
  @include offset-words-txt1;
  align-self: flex-end;
  text-align: right;
  width: 95%;
  font-size: 48px;
  margin-bottom: 0px;
  line-height: 56px;
}

.vp-card-1.card-grey,
.vp-card-2.card-grey {
  gap: 100px;
}

.vp-card-1,
.vp-card-2 {
  .card-grey-img {
    width: 62%;
    height: auto;
  }
  .card-grey-body {
    gap: 50px;
  }
  .card-grey-body-title {
    font-size: 84px;
  }
  .card-grey-body-text {
    font-size: 16px;
  }
}
.vp-card-2 {
  .card-grey-img {
    width: 66%;
    height: auto;
  }
}
.vp-img-div-1 {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  height: auto;
  overflow: hidden;
  gap: 50px;
  margin: auto;
  img {
    width: 48%;
    object-fit: cover;
  }
}

.vp-mission-btn{
  &:hover{
    color:white;
  }
}

.vp-img-1 {
  width: 100%;
  height: auto;
}

.vp-img-div-3 {
  margin-bottom: 50px;
  display: flex;

  overflow: hidden;
  flex-direction: row;
  height: auto;
  gap: 50px;
  img {
    width: 48%;
    object-fit: cover;
  }
}

.vp-img-2 {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.vp-img-div-4 {
  padding-top: 25px;
  display: flex;
  overflow: hidden;
  flex-direction: row;
  height: auto;
  gap: 50px;
  img {
    width: 48%;
    object-fit: cover;
  }
}

.vp-card-1 {
  margin-bottom: 50px;
}

.vp-locations {
  padding: 100px 50px;
}
.vp-locations-title {
  @include red-sub-header-text;
  margin-bottom: 50px;
}

.vp-locations-cards-div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.vp-locations-card {
  width: 31%;
  img {
    width: 100%;
    height: auto;
    margin-bottom: 36px;
    &:hover {
      --marBotStart:36px;
      --marBotEnd:33px;
      animation-name: cardexpand;
      animation-duration: .3s;
      animation-fill-mode: forwards;
    }
  }
}

.vp-locations-card-body {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0px 10px;
}

.vp-locations-card-text {
  font-family: $font-3;
  text-transform: uppercase;
  color: $off-black;
  font-size: 14px;
  letter-spacing: 0.15em;
}

.vp-mission-statement-block {
  @include offset-words-block;
  margin-bottom: 0px;
  .mission-txt1 {
    width: 50%;
  }
}

@media screen and (max-width: 900px) and (min-width: 500px) {
  .vp-hero-title {
    font-size: 64px;
  }
}
@media screen and (max-width: 1213px) {
  .vp-card-1.card-grey,
.vp-card-2.card-grey {
  gap: 50px;
}

.vp-card-1,
.vp-card-2 {
  .card-grey-img {
    width: 45%;
  }
.card-grey-body{
  gap:25px;
}
.card-grey-body-title{
  font-size: 52px;
}

}
}
@media screen and (max-width: 760px) {
  .vp-hero {
    padding: 50px;
  }

  .vp-hero-sub {
    font-size: 14px;
  }

  .vp-hero-title {
    font-size: 28px;
    width: 100%;
  }

  .go-to-vp-btn {
    @include dark-red-btn;
    margin: auto;
    width: 100%;
    max-width: 400px;
  }

  .vp-quote {
    padding: 50px;
    gap: 15px;
  }

  .vp-quote-subhead {
    font-size: 10px;
    width: 100%;
  }

  .vp-quote-text {
    font-size: 18px;
    width: 100%;
    line-height: 21px;
  }

  .vp-card-2.card-grey,
  .vp-card-1.card-grey {
    gap: 50px;
  }

  .vp-card-1,
  .vp-card-2 {
    .card-grey-img {
      width: 100% !important;
      height: auto !important;
    }
    .card-grey-body {
      gap: 25px !important;
    }
    .card-grey-body-title {
      font-size: 48px !important;
    }
    .card-grey-body-text {
      font-size: 14px;
    }
    .card-grey-button{
      margin:auto;
    }
  }

  .vp-card-1 {
    margin-bottom: 25px;
  }

  .vp-locations {
    padding: 50px 50px 0px 50px;
  }

  .vp-locations-title {
    font-size: 14px;
  }

  .vp-locations-cards-div {
    flex-direction: column;
  }

  .vp-locations-card {
    width: 100%;
    img {
      width: 100%;
      &:hover {
        animation-name:none;
        margin-bottom: 36px;
      }
    }
  }

  .vp-locations-card-body {
    margin-bottom: 50px;
  }

  .vp-learn-more-btn {
    width: 85%;
    max-width: 400px;
  }

  .vp-mission-statement-block {
    padding: 50px;
    gap: 50px;
    .mission-txt1 {
      width: 100%;
    }
  }

  .vp-img-div-1,
  .vp-img-div-3,
  .vp-img-div-4 {
    gap: 25px;
  }
  
  .vp-img-div-3,
  .vp-img-div-4 {
    margin-bottom: 25px;
    margin-top: 0px;
  }
}
