@import "./variables";

.vector {
  width: 100%;
  &:not(:last-of-type) {
    margin-bottom: 8.75px;
  }
}

.frame7 {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 15px 25px;
  border-bottom: 1px solid $cool-grey;
}

.galerie-brandmark-white {
  width: 145.68px;
  height: 35.05px;
}

.nav-bar {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 50px;
  flex-wrap: nowrap;
  gap: 80px;
  width: 100vw;
  height: 100px;
  max-width: 2000px;
  position: absolute;
  top:0;
  left:0;
  margin: auto;
  background-color: white;
  z-index: 5;
}

.galerie-logo {
  width: 145.68px;
  height: 35.05px;
  margin: 20px 0px 0px 0px;
  
  z-index: 5;
  
}

.galerie-logo-link{
  z-index: 5;
}

.navbar-buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 0px;
  gap: 50px;
  width: 70%;

}

.navbar-link {
  font-family: $font-3;
  float: left;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  color: $black;
  &:hover{
        --color1:black;
        --color2:#A12A2B;
        animation-duration: .2s;
        animation-name: hovercolor;
        animation-fill-mode: forwards;
    }
}

.navbar-btn {
  @include dark-red-btn;
  z-index: 5;
  --padding1:16px 36px;
  --padding2:19px 39px;
  --margin1:3px;
  --margin2:0px;
  animation-duration: .3s;
  animation-name: leavebtn;
  animation-fill-mode: forwards;
  &:hover{
    color:white;
    animation-duration: .3s;
    animation-name: expandbtn;
    animation-fill-mode: forwards;
  }
}

.navbar-btn-text {
  padding: 0px;
  margin: 0px;
  white-space: nowrap;
}

.navbar-buttons-expanded {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 25px 50px 25px;
  width: 100%;
}

.link-expanded, .option-expanded{
  font-family: $font-3;
  font-style: normal;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  color: $black;
  text-decoration: none;
  
}

.link-expanded{
  padding: 50px 50px 50px 15px;
  flex: 1;
}

.option-expanded{
  padding: 0px 0px 50px 15px;
}

.contact-us-btn{
  @include dark-red-btn;
  color: white !important;
  margin-top: 50px;
}

.nav-divider {
  width: 100%;
  height: 0px;
  opacity: 0.25;
}

.navbar-btn-expanded {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px 36px;
  gap: 10px;
  width: 290px;
  height: 54px;
  background: $dark-red;
  border-radius: 8px;
}

.navbar-btn-text-expanded {
  font-family: $font-3;
  font-style: normal;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  color: $white;
}

.nav-dropdown-div {
  display: flex;
  flex-direction: column;
  height: 100%;
  // position: relative; 
  white-space: nowrap;
}

.hover-div{
  position: absolute;
  height: 100px;
  z-index: 1;
}
.hover-div.aboutus{
  width: 170px;
  top:-25px;
  left:-25px;
}
.hover-div.thecollection{
  width: 230px;
  top:-25px;
  left:-25px;
}
.hover-div.ourcompanies{
  width: 235px;
  top:-25px;
  left:-25px;
}

.custom-dropdown-menu {
  position: absolute;
  left:0%;
  top:100px;
  border-radius: 0px !important;
  gap:50px;
  // margin-top: 100px !important;
  width: 100vw;
  padding:50px;
  visibility: visible;
  transition: visibility .1s, opacity .1s ease-in-out;
  opacity: 1;
}
.custom-dropdown-menu-closed{
  position: absolute;
  left:0%;
  top:100px;
  border-radius: 0px !important;
  gap:50px;
  // margin-top: 100px !important;
  width: 100vw;
  padding:50px;
  visibility:hidden;
  opacity: 0;
  transition: visibility .3s, opacity 0.3s ease-in-out;
 
}

.nav-dropdown-item-div{
  margin-bottom: 0px;
  padding:0px 25px;
  &:last-child{
    margin-bottom: 0px;
  }
}

.nav-dropdown-item-div.white{
  &:hover{
    cursor: pointer;
      --color1:#041C2C;
      --color2:#122937;
      --color3:#192F3D;
      animation-name: navhover;
      animation-duration: .3s;
      animation-fill-mode: forwards;
    }
}

.nav-dropdown-item-div.black{
  &:hover{
    cursor: pointer;
      --color1:white;
      --color2:#FCFCFC;
      --color3:#F7F7F7;
      animation-name: navhover;
      animation-duration: .2s;
      animation-fill-mode: forwards;
    }
}


.nav-dropdown-item {
  font-family: $font-3;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: .15em;
  padding: 25px 0px;
  margin-bottom: 0px;
  width:100%;
  &:hover{
    --color1:black;
    --color2:#A12A2B;
    animation-duration: .2s;
    animation-name: hovercolor;
    animation-fill-mode: forwards;
}
}
.nav-dropdown-item.white {
  &:hover{
    --color1:white;
    --color2:white;
    animation-duration: .2s;
    animation-name: hovercolor;
    animation-fill-mode: forwards;
}
}

a.dropdown-item {
  &:active {
    background-color: white;
  }
}

.dropdown-toggle {
  background: transparent !important;
  border: none !important;
  &:after {
    display: none !important;
  }
}

.dropdown-title-div{
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  gap:15px;
}

.dropdown-title{
  z-index: 4;
  text-decoration: none;
  &:hover{
    --color1:black;
    --color2:#A12A2B;
      animation-duration: .2s;
      animation-name: hovercolor;
      animation-fill-mode: forwards;
    text-decoration: none !important;
  }
}
.dropdown-title.white{
  color: white !important;
  animation-name: leaveunderline;
  animation-fill-mode: forwards;
  &:hover{
      animation-duration: .1s;
      animation-name: hoverunderline;
      animation-fill-mode: forwards;
    text-decoration: none !important;
  }
}

.dropdown-caret, .caret-down-icon, .caret-up-icon{
  padding-bottom: 6px;
  z-index: 5;
}

.list-icon, .x-icon{
  z-index: 5;
}


.hamburger-dropdown-container{
  width:100%;
}

.hamburger-dropdown-div{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width:100%;
  height: 100%;
  // padding: 50px 0px;
}

.hamburger-caret-div{
 padding:50px 10px 50px 50px;
}



.hamburger-options{
  display: flex;
  flex-direction: column;
}

@media screen and (max-width:1122px){
  .navbar-buttons{
    gap:25px;
  }
  .hover-div.aboutus{
    width: 150px;
    top:-25px;
    left:-25px;
  }
  .hover-div.thecollection{
    width: 195px;
    top:-25px;
    left:-13px;
  }
  .hover-div.ourcompanies{
    width: 200px;
    top:-25px;
    left:-13px;
  }

  .dropdown-title-div{
    gap:8px;
  }
  
}