.management-container {
  max-width: 2000px;
  margin: auto;
  min-height:100vh;
}

.management-hero {
  padding: 100px;
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.management-hero-sub {
  @include red-sub-header-text;
  font-size: 18px;
}

.management-offset-block {
  @include offset-words-block;
}

.mob-3{
  margin:100px 0px;
}

.mob-4{
  margin-top:100px;
}

.management-hero-title {
  font-family: $font-1;
  font-size: 64px;
  text-transform: capitalize;
  width: 80%;
  margin: 0px;
}

.management-offset-text1 {
  @include offset-words-txt1;
  font-family: $font-1;
  font-size: 28px;
  margin-bottom: 0px;
}

.management-offset-text2 {
  @include offset-words-txt2;
  font-size: 22px;
  text-align: left;
  margin-bottom: 0px;
}

.management-learn-more-btn {
  @include dark-red-btn;
  margin: auto;
}

.management-block-1 {
  margin-bottom: 0px !important;

}

.management-text-block {
  padding: 100px;
  display: flex;
  flex-direction: column;
  h1 {
    width: 80%;
    align-self: flex-end;
    text-align: right;
    font-family: $fancy-font;
    font-size: 84px;
  }
}

#management-block-2-img {
  width: 100%;
  height: auto;
}

.management-block-3 {
  display: flex;
  overflow: hidden;
  flex-direction: row;
  height: auto;
  gap: 50px;
  img {
    width: 50%;
    height: auto;
    object-fit: cover;
  }
}

.management-card-1 {
  .card-white-title{
   font-size: 54px !important;
   line-height: normal !important;
  }
  .card-white-text{
   font-size: 22px !important;
   line-height: normal !important;
    margin-bottom: 0px;
   &:first-of-type{
    margin-bottom: 16px;
   }
  }
 }

.management-card-2 {
  @include companies-card;
  gap: 100px;
  padding: 100px;
  padding-top: 0px;
}

.management-card-2-text {
  @include companies-card-text;
  width: 50%;
}

.management-card-2-img {
  @include companies-card-img;
  width: 50%;
}

.card-grey.management-card-grey {
  gap: 100px;
  margin-bottom: 100px;
  .card-grey-body-title{
    font-size: 54px;
  }
  .card-grey-body-text {
    margin-bottom: 0px;
    font-size: 16px;
    padding-bottom: 25px;
  }
 
  .card-grey-img{
    width:50%;
  }
  .card-grey-body-text{
    font-size: 16px;
  }
  .card-grey-button{
    --padding1:16px 36px;
    --padding2:19px 39px;
    --margin1:3px;
    --margin2:0px;
    animation-duration: .3s;
    animation-name: leavebtn;
    animation-fill-mode: forwards;
    &:hover{
      animation-duration: .3s;
      animation-name: expandbtn;
      animation-fill-mode: forwards;
    }
  }
}

.management-card-4 {
  gap: 100px;
  width:100%;
  #management-card-4-img {
    width: 50% !important;
  }
  .card-grey-body {
    width: 50%;
  }
  .card-grey-body-text{
    font-size: 22px;
    line-height: normal;
  }
  .card-grey-body-title{
    font-size: 54px;
    line-height: normal;
  }
}
.management-our-team-btn{
  @include dark-red-btn;
  margin: 3px auto;
  --padding1:16px 36px;
  --padding2:19px 39px;
  --margin1:3px auto;
  --margin2:0px auto;
  animation-duration: .3s;
  animation-name: leavebtn;
  animation-fill-mode: forwards;
  &:hover{
    animation-duration: .3s;
    animation-name: expandbtn;
    animation-fill-mode: forwards;
  }
}
.management-our-team-btn-div{
  display: flex;
  justify-content: center;
  align-content: center;
}

@media screen and (max-width: 992px) {
  #management-card-development-img{
    max-height: 600px;

  }
  .management-card-1 {
    .card-white-body{
      margin:auto;
    }
   }
   .card-grey.management-card-grey {
      gap:25px;
     .card-grey-body{
      gap:15px;
     }
  }
  .management-card-2 {
    gap:25px;
  }
  .management-hero-title {
    width: 100%;
  }
  .management-card-4 {
    
    gap: 50px !important;
    .card-grey-body{
      gap: 25px !important;
    }
    #management-card-4-img {
      width: 100% !important;
    }
    .card-grey-body {
      width: 100% !important;
    }
    .card-grey-body-text{
      font-size: 18px !important;
    }
    .card-grey-body-title{
      font-size: 48px !important;
    }

  }
  #management-card-4-img{
    max-height: 600px;
    object-position: center top;
  }
}

@media screen and (max-width: 760px) {
  .management-hero {
    padding: 50px;
    gap: 25px;
  }

  .management-hero-sub {
    font-size: 14px;
  }

  .management-offset-block {
    padding: 50px;
    gap: 50px;
  }

  .mob-3{
    margin:0px;
  }

  .mob-4{
    margin-top:0px;
  }

  .management-hero-title {
    font-size: 28px;
    width: 100%;
  }

  .management-offset-text1 {
    text-align: left;
    align-self: left;
    width: 100%;
  }

  .management-offset-text2 {
    font-size: 18px;
    align-self: left;
    width: 100%;
  }

  .management-learn-more-btn {
    width: 100%;
    max-width: 500px;
  }

 

  .management-block-3 {
    gap:25px;
    img {
      width: 48%;
  }
}

  .management-text-block {
    padding: 50px;
    h1 {
      width: 93%;
      font-size: 28px;
    }
  }

  .management-card-1 {
   .card-white-title{
    font-size: 48px !important;
   }
   .card-white-text{
    font-size: 14px !important;
   }
  }

  .management-card-2 {
    padding: 50px;
    padding-top: 0px;
    gap: 50px;
    flex-direction: column-reverse;
  }

  .management-card-2-text {
    font-size: 18px;
    margin: 0px;
    line-height:normal;
    gap: 25px;
    text-align:left;
    width:100%;
  }

  .management-card-2-img {
    max-height: 500px;
    width: 100%;
    margin: auto;
  }
  
  .management-block-2-img{
    width:100%;
    height:auto;
  }
  .card-grey.management-card-grey {
    flex-direction: column;
    gap:50px;
    margin-bottom: 0px;
    .card-grey-body-title{
      font-size: 48px;
    }
    .card-grey-body{
      gap:25px;
    }
    .card-grey-img{
      object-position: center center;
      margin:auto;
      width:100%;
      height: auto;
    }
    .card-grey-body-text{
      font-size: 14px;
    }
  }

 
}
