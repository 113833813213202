.contact-us-container {
    max-width: 2000px;
    margin: auto;
    min-height: 100vh;
    border-bottom: 1px solid $cool-grey-opaque;
}

.contact-us-hero-body {
    display: flex;
    flex-direction: column;
    gap: 100px;
}

.contact-us-container {
    background-color: $dark-blue;
    color: white;
}

.contact-us-hero {
    display: flex;
    flex-direction: column;
    gap: 50px;
    padding: 80px 50px;
    border-bottom: 0.5px solid $cool-grey-opaque;
}

.contact-us-hero-title {
    font-family: $font-1;
    font-weight: 400;
    font-size: 36px;
    line-height: 39px;
    margin-bottom: 0px;
}

.contact-us-hero-general,
.contact-us-hero-address {
    line-height: 18px;
    letter-spacing: 0.15em;
    h6 {
        font-family: $font-3;
        margin-bottom: 25px;
        text-transform: uppercase;
        font-weight: 400;
        font-size: 14px;
        letter-spacing: 0.15em;
    }
    p {
        font-family: $font-2;
        margin-bottom: 15px;
        text-transform: none;
        font-weight: 300;
        font-size: 14px;
        letter-spacing: 0.15em;
    }
}

.contact-us-brochure-btn {
    @include dark-red-btn;
    margin-top: 50px;
}

.contact-arrow-icon {
    width: 20px;
}

.contact-collection-card-container {
    display: flex;
    flex-direction: column;
    gap: 63px;
    margin: auto;
}

.contact-collection-card {
    margin: auto;
    font-family: $font-3;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.15em;
    text-transform: uppercase;
    width: 100%;
    img {
        width: 100%;
        height: auto;
        margin-bottom: 38px;
        object-fit: cover;
       
    }
    
}

.contact-collection-card-text {
    display: flex;
    flex-direction: row;
    padding: 0px 5px;
    font-family: $font-3;
    justify-content: space-between;
    p {
        margin: 0;
    }
    &:hover {
        cursor: pointer;
    }
}

.contact-collection-text-underline{
        animation-duration: .1s;
        animation-name: hoverunderline;
        animation-fill-mode: forwards;
}

.contact-us-collection-container {
    padding: 50px;
    
    h1 {
        font-family: $font-3;
        font-size: 18px;
        text-transform: uppercase;
        margin-bottom: 50px;
        letter-spacing: 0.15em;
    }
}

.contact-address, .contact-phone-number{
    font-family: $font-2;
    font-size: 14px;
    text-decoration: none;
}

@media screen and (min-width: 760px) {
    .contact-us-hero {
        padding: 100px;
        height: 90vh;
        justify-content: space-between;
        margin: auto;
        justify-content: center;
    }

    .contact-us-hero-body {
        flex-direction: row;
        gap: 100px;
    }

    .contact-us-hero-title {
        font-size: 48px;
        line-height: 52px;
    }

    .contact-collection-card-container {
        flex-direction: row;
        gap: 50px;
    }

    .contact-us-collection-container {
        padding: 100px;
        h1 {
            font-size: 18px;
            line-height: 23px;
            letter-spacing: 0.15em;
            text-transform: uppercase;
            text-align: left;
            font-family: $font-3;
        }
    }

    .contact-us-hero-general,
    .contact-us-hero-address {
        line-height: 18px;
        letter-spacing: 0.15em;
        h6 {
            font-size: 18px;
        }
        p {
            font-size: 16px;
        }
    }

    .contact-collection-card {
        width: 50%;
        img {
            width: 100%;
            min-width: 280px;
            &:hover {
                --marBotStart:38px;
                --marBotEnd:34px;
                animation-name: cardexpand;
                animation-duration: .3s;
                animation-fill-mode: forwards;
              }
        }
    }

    .contact-address, .contact-phone-number{
        font-size: 16px;
    }


    .contact-us-brochure-btn {
        margin-top: 3px;
        --padding1:16px 36px;
        --padding2:19px 39px;
        --margin1:3px;
        --margin2:0px;
        animation-duration: .3s;
        animation-name: leavebtn;
        animation-fill-mode: forwards;
        &:hover{
            animation-duration: .3s;
            animation-name: expandbtn;
            animation-fill-mode: forwards;
          }
    }

}

@media screen and (min-width: 760px) and (max-width: 1200px) {
    .contact-us-collection-container {
        padding: 50px;
    }
}
