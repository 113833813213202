// @import "~bootstrap/scss/bootstrap";

@import "./reset";
@import "./variables";
@import './navbar';
@import "./footer";
@import "./corso";
@import "./careers";
@import "./press";
@import "./contactUs";
@import "./collections";
@import "./home";
@import "./aboutUs";
@import "./companies";
@import "./galerieCapital";
@import "./home";
@import "./team";
@import "./villagePark";
@import "./components";
@import "./management";
@import "./development";
@import "./testimonials";
@import "./healthSustainability";


.overlay {
    position: fixed;
    top: 100px;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
    transition: opacity 0.8s;
    z-index: 1;
}

.overlay.visible {
    right: 0;
    bottom: 0;
    opacity: 1;
    transition: opacity 0.6s;
}
