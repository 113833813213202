// testimonials
.testimonial-container {
  padding: 100px 50px;
  height: 100%;
  position: relative;
  background-color: $light-grey;
}

.text-testimonial-container {
  height: 100%;
  position: relative;
  background-color: $light-grey;
  padding: 0px 50px;
}

.testimonial-card {
  display: flex;
  flex-direction: row;
  min-height: 500px;
}

.text-testimonial-card {
  display: flex;
  flex-direction: row;
  gap: 50px;
  background: $dark-blue;
  color: white;
}

.video-container {
  width: 70%;
  position: relative;
  height: auto;
  max-height: none;
}

.testimonial-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
  z-index: 999;
}

.testimonial-body {
  display: flex;
  flex-direction: column;
  padding: 100px 25px 100px 100px;
  gap: 50px;
  margin: auto 0px;
}

.text-testimonial-body {
  display: flex;
  flex-direction: column;
  padding: 100px 112px;
  width: 90%;
  margin: auto;
  gap: 50px;
  height: fit-content;
  justify-content: center;
}

.testimonial-quote {
  font-family: $font-1;
  margin-bottom: 0px;
}

.text-testimonial-quote {
  font-family: $font-1;
  font-size: 28px;
  margin-bottom: 0px;
  line-height: 35.45px;
}

.testimonial-name,.text-testimonial-name  {
  font-size: 18px;
  font-family: $font-3;
  text-transform: uppercase;
  margin-bottom: 0px;
  letter-spacing: .15em;
}

.text-testimonial-name {
  font-size: 14px;
  font-family: $font-3;
}

.prev-arrow,.prev-arrow-disabled {
  position: absolute;
  top: 13%;
  right: 125px;
  z-index: 5;
}

.prev-arrow-disabled {
  cursor: not-allowed;
  width: fit-content;
}

.prev-arrow{
  cursor: pointer;
}
.text-prev-arrow {
  position: absolute;
  top: 50%;
  left: 50px;
  z-index: 5;
  cursor: pointer;
}

.next-arrow {
  position: absolute;
  top: 13%;
  right: 50px;
  z-index: 5;
  cursor: pointer;
}
.arrow-left-icon-disabled{
  font-size: 32px;
}
.arrow-right-icon, .arrow-left-icon{
  font-size: 32px;
  &:hover {
    cursor: pointer;
    --size1:32px;
    --size2:35px;
    animation-name: arrowexpand;
    animation-duration: .3s;
    animation-fill-mode: forwards;
  }
}

.arrow-right-icon-text, .arrow-left-icon-text{
  font-size: 25px;
  &:hover {
    cursor: pointer;
    --size1:25px;
    --size2:27px;
    animation-name: arrowexpand;
    animation-duration: .3s;
    animation-fill-mode: forwards;
  }
}


.text-next-arrow {
  position: absolute;
  top: 50%;
  right: 50px;
  z-index: 5;
  cursor:pointer;
}

.icon-container {
  width: 100%;
  position: absolute;
  top: 45%;
}

// #Capa_1 is the play button SVG on testimonial videos
#Capa_1 {
  width: 100%;
  height: 80px;
  &:hover{
    cursor: pointer;
  }
}

.testimonials-section-title {
  @include red-sub-header-text;
  margin-bottom: 55px;
  letter-spacing: .15em;
}

@media screen and (min-width: 1250px) {
  .video-container {
    min-width: 350px;
  }
}

@media screen and (max-width: 1300px) and (min-width: 760px) {
  .testimonial-card {
    margin: auto;
    height: 100%;
    gap: 0px;
    min-height: auto;
  }

  .testimonial-quote {
    font-size: 32px;
  }

  .testimonial-body {
    padding: 50px;
    margin: auto 0px;
  }

  .video-container {
    max-height: none;
    width: 100%;
  }

  .testimonial-video {
    min-height: 350px;
  }
}

@media screen and (max-width: 1090px) and (min-width: 760px) {
  .testimonial-quote {
    font-size: 28px;
  }

  .testimonial-container {
    padding: 25px 50px;
  }

  .prev-arrow, .prev-arrow-disabled {
    top: 8%;
  }

  .next-arrow {
    top: 8%;
  }

  .testimonial-body {
    gap: 25px;
  }

  #Capa_1 {
    height: 50px;
   
  }
}

@media screen and (max-width: 760px) {
  .testimonial-container {
    padding: 50px;
    height: 100%;
    min-height: none;
    margin: auto;
  }

  .testimonial-card {
    flex-direction: column;
    gap: 0px;
    justify-content: center;
    left: 0px;
    position: relative;
    min-height: auto;
  }

  .video-container {
    width: 100%;
    height: auto;
    text-align: center;
    min-width: none !important;
    min-height: 250px;
    margin: auto;
  }

  .testimonial-video {
    height: auto;
    min-height: 250px;
    min-width: none !important;
    object-fit: cover;
    object-position: normal !important;
  }

  .testimonial-name {
    font-size: 14px;
  }

 

  .testimonial-body {
    flex-direction: column;
    padding: 50px;
    gap: 50px;
  }

  .text-testimonial-body {
    flex-direction: column;
    padding: 50px 25px;
    gap: 25px;
  }

  .testimonial-quote {
    font-size: 28px;
  }

  .text-testimonial-quote {
    font-size: 24px;
    line-height: 28.13px;
  }
  .arrow-right-icon, .arrow-left-icon{
    &:hover {
      animation-name: none;
    }
  }
  
  .arrow-right-icon-text, .arrow-left-icon-text{
    &:hover {
      animation-name: none;
    }
  }
  
  .prev-arrow, .prev-arrow-disabled {
    top: 6%;
    right:100px;
  }

  .arrow-left-icon, .arrow-right-icon, .arrow-left-icon-disabled{
    font-size: 25px;
  }

  .text-prev-arrow {
    top: 40%;
    left: 25px;
  }

  .next-arrow {
    top: 6%;
  }

  .text-next-arrow {
    top: 40%;
    right: 25px;
  }
  
  #Capa_1 {
    height: 50px;
  }
}

@media screen and (max-width: 760px) and (min-width: 200px) {
  .testimonial-container {
    padding: 50px;
  }

  #Capa_1 {
    height: 50px;
  }
}
