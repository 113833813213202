.development-container {
  max-width: 2000px;
  margin: auto;
  min-height:100vh;
}

.development-hero {
  padding: 100px;
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.development-hero-sub {
  @include red-sub-header-text;
  font-size: 18px;
}

#development-block-1-img,
#development-block-3-img {
  width: 100%;
  height: auto;
}

#development-block-2a-img,
#development-block-2b-img,
#development-block-4a-img,
#development-block-4b-img {
  width: 50%;
  height: auto;
}

.development-block-2,
.development-block-4 {
  display: flex;
  flex-direction: row;
  gap: 50px;
  overflow: hidden;
  img {
    width: 47%;
  }
}

.development-hero-title {
  font-family: $font-1;
  font-size: 64px;
  text-transform: capitalize;
  width: 90%;
  margin-bottom: 0px;
}

// .development-learn-more-btn {
//   @include dark-red-btn;
//   margin: auto;
// }

.development-offset-block {
  @include offset-words-block;
}

.development-offset-text1 {
  @include offset-words-txt1;
  font-family: $font-1;
  font-size: 28px;
  line-height: normal;
  margin-bottom: 0px;
}

.development-offset-text2,
.development-offset-text4 {
  @include offset-words-txt2;
  font-size: 22px;
  line-height: normal;
  margin-bottom: 0px;
}

.development-offset-text2.small {
  font-size: 16px;
}

.development-offset-text3 {
  @include offset-words-txt1;
  font-family: $font-2;
  font-size: 22px;
  line-height: normal;
  margin-bottom: 0px;
}

.dev-card-1,
.dev-card-2 {
  @include companies-card;
  gap: 100px;
  padding: 100px;
}

.dev-card-1 {
  text-align: right;
  padding-bottom: 0px;
}

.dev-card-2 {
  padding-top: 0px;
}

.dev-card-text {
  @include companies-card-text;
}

.dev-card-img {
  @include companies-card-img;
}

.dev-team-btn {
  width: fit-content !important;
  margin: 3px auto;
  --padding1:16px 36px;
  --padding2:19px 39px;
  --margin1:3px auto;
  --margin2:0px auto;
  animation-duration: .3s;
  animation-name: leavebtn;
  animation-fill-mode: forwards;
  &:hover{
    animation-duration: .3s;
    animation-name: expandbtn;
    animation-fill-mode: forwards;
  }
}
@media screen and (max-width: 1000px) {
  .dev-card-1, .dev-card-2{
    gap: 50px;
  }
}

@media screen and (max-width: 760px) {
  .development-hero {
    padding: 50px;
    gap: 25px;
  }

  .development-hero-sub {
    font-size: 14px;
  }

  .development-hero-title {
    font-size: 28px;
    width: 100%;
  }

  .development-offset-block {
    padding: 50px;
    gap: 50px;
  }

  .development-offset-text1 {
    font-size: 28px;
    width: 100%;
    line-height: 30px;
  }

  .development-offset-text3 {
    width: 100%;
    font-size: 18px;
  }

  .development-offset-text2 {
    font-size: 18px;
    width: 100%;
    text-align: left;
    align-self: flex-start;
    line-height: normal;
  }

  .development-offset-text2.small {
    font-size: 14px;
  }

  .development-offset-text4 {
    @include offset-words-txt2;
    font-size: 18px;
    width: 100%;
    text-align: left;
    align-self: flex-start;
  }

  .dev-card-1 {
    flex-direction: column-reverse;
    gap: 50px;
    padding: 50px;
    padding-bottom: 0px;
  }

  .dev-card-2 {
    flex-direction: column;
    gap: 50px;
    padding: 50px;
    padding-top: 0px;
  }

  .dev-card-text {
    font-size: 18px;
    margin: auto;
    line-height: normal;
    gap: 25px;
    text-align: left;
  }

  .dev-card-img {
    max-height: 500px;
    width: 100%;
    margin: auto;
  }

  .development-block-2,
  .development-block-4 {
    gap: 25px;
  }

  #development-block-3-img {
    width: 100%;
    height: auto;
    padding: 0px 50px !important;
  }

  // .development-learn-more-btn {
  //   width: 100%;
  //   max-width: 400px;
  // }
  
  .dev-team-btn {
    width: fit-content !important;
    animation-name: none;
  }
}
