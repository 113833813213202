// difference
.about-container{
  max-width: 2000px;
  margin: auto;
  min-height: 100vh;
}
.about-difference-sub {
  @include red-sub-header-text;
  margin: 0px;
}

.about.card-white {
  padding-bottom: 0px;
  width:100% !important;
  max-width: none !important;
  .card-white-text{
    font-size: 16px !important;
    line-height: normal;
  }
}
.about-divider-line{
  width:100%;
  height:1px;
  border-bottom: 1px solid $cool-grey;
}
.about-header.card-white {
  gap: 100px;
  justify-content: space-between;
  margin:0px;
  width:100% !important;
  max-width: none !important;
}

.about-header.card-white-body {
  margin: auto 0px;
  width: 60%;
  max-width: none;
}

.about-img-1.card-white-img {
  margin: 0px;
  align-self: flex-end;
  width: 40%;
  max-height: 700px;
}

.about-header.card-white-text {
  color: $off-black;
  font-size: 16px;
  line-height: 19px;
  padding-bottom: 25px !important;
  margin-bottom: 0px;
}

.health-btn {
  width: fit-content !important;
  max-width: fit-content !important;
  margin: 3px;
  --padding1:16px 36px;
  --padding2:19px 39px;
  --margin1:3px;
  --margin2:0px ;
  animation-duration: .3s;
  animation-name: leavebtn;
  animation-fill-mode: forwards;
  &:hover{
    color:white;
    animation-duration: .3s;
    animation-name: expandbtn;
    animation-fill-mode: forwards;
  }
}


// values

.about-values-title {
  @include red-sub-header-text;
  padding-left: 100px;
  margin: 0px;
  margin-top: 100px;
}

.about-values-img-div {
  width: 40%;
  height: auto;
}

.about-values-img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.about-values-card {
  display: flex;
  flex-direction: row;
  gap: 200px;
  margin-bottom: 100px;
}

.about-values-card-title {
  font-family: $fancy-font;
  color: $off-black;
  font-size: 84px;
  margin-bottom: 0px;
}

.about-values-card-body {
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 25px;
  width: 60%;
}

.about-values-card-number {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  font-family: $font-2;
  color: $dark-red;
  letter-spacing: .15em;
  .about-values-card-num1 {
    font-size: 36px;
    margin: 0px;
  }
  .about-values-card-num2 {
    font-size: 18px;
    margin: 0px;
  }
}


// model

.about-model {
  padding: 100px;
}

.about-model-body {
  display: flex;
  flex-direction: row;
  gap: 100px;
  width: 100%;
  height: auto;
  margin-bottom: 0px;
}

.about-model-title {
  @include red-sub-header-text;
  margin-bottom: 100px;
}

.about-model-blurb-title {
  font-family: $fancy-font;
  color: $off-black;
  font-size: 54px;
  margin-bottom: 25px;
}

.about-model-blurb {
  margin-bottom: 100px;
}

.about-model-blurb.last-blurb {
  margin-bottom: 0px;
}

.about-model-blurb-text {
  font-family: $font-2;
  color: $off-black;
  font-size: 16px;
  margin-bottom: 0px;
}

.about-model-body-text {
  width: 40%;
  height: auto;
  margin-bottom: 0px;
}

.about-model-img {
  width: 60%;
  height: auto;
  object-fit: contain;
  align-self: flex-start;
  margin: auto;
  max-height: 970px;
}

.about-our-team-btn {
  width: fit-content !important;
  max-width: fit-content !important;
  margin:3px auto;
  --padding1:16px 36px;
  --padding2:19px 39px;
  --margin1:3px auto;
  --margin2:0px auto;
  animation-duration: .3s;
  animation-name: leavebtn;
  animation-fill-mode: forwards;
  &:hover{
    color:white;
    animation-duration: .3s;
    animation-name: expandbtn;
    animation-fill-mode: forwards;
  }
  
}


// history

.about-history {
  padding: 100px 100px 0px 100px;
  border-top: 1px solid $cool-grey;
  margin-top: 0px;
}

.about-history-header-text {
  font-family: $font-1;
  color: $off-black;
  font-size: 48px;
  margin-bottom: 0px;
}

.about-history-header-title {
  @include red-sub-header-text;
  margin-bottom: 25px;
}


// created

.about-created {
  background-color: $light-grey;
  padding: 100px;
  margin-top: 100px;
}

.about-created-title {
  @include red-sub-header-text;
  margin-bottom: 50px;
}

.about-created-boxes,
.about-created-group {
  width: 100%;
  display: flex;
  flex-direction: row;
}
.about-created-group {
  border-right: 1px solid $cool-grey;
}
.box4, .box2, .box1{
  border-left: 1px solid $cool-grey;
}
.about-created-box-div {
  padding: 0px;
  width:fit-content;
  flex: 1;
}
.about-created-box{
width:fit-content;
margin:auto;

}
  .about-created-box-text1 {
    width: fit-content;
    font-family: $font-3;
    font-size: 14px;
    text-transform: uppercase;
    margin-bottom: 15px;
    white-space: nowrap;
    letter-spacing: .15em;
  }
  .about-created-box-text2 {
    width: fit-content;
    font-family: $font-1;
    font-size: 48px;
    white-space: nowrap;
  }



// new timeline

.timeline-carousel-container {
  padding: 50px 100px 100px 100px;
  position: relative;
  width: 100%;
  min-height: 730px;
}

.time-year-range-div{
  position: absolute;
  top:360px;
  left:-50px;
}

.timeline-year-range {
  font-family: $font-1;
  font-size: 100px;
  transform:rotate(-90deg);
  margin-bottom: 100px;
}

.timeline-carousel-card {
  display: flex;
  flex-direction: column;
  padding-left:180px;
}

.timeline-img-container {
  display: flex;
  flex-direction: row;
  gap: 25px;
  width: 100%;
  padding-bottom: 40px;
}

.timeline-img-card {
  text-align: center;
  font-family: $font-3;
  text-transform: uppercase;
  font-size: 18px;
  width: 31%;
  p {
    margin-top: 15px;
    letter-spacing: 0.15em;
    margin-bottom: 0px;
  }
}

.timeline-carousel-img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.timeline-carousel-body {
  display: flex;
  flex-direction: row;
  gap: 50px;
  min-height: 360px;
}

.timeline-carousel-body-block {
  width: 25%;
}

.timeline-body-year {
  font-family: $fancy-font;
  font-size: 84px;
  margin-bottom: 0px;
}

.timeline-body-blurb {
  margin-bottom: 0px;
  font-family: $font-2;
  font-size: 16px;
}

.timeline-next-arrow {
  position: absolute;
  top: -5%;
  right: 10%;
}
.timeline-next-arrow-icon, .timeline-prev-arrow-icon{
  font-size: 25px;
  &:hover{
    cursor: pointer;
      --size1:25px;
      --size2:28px;
      animation-name: arrowexpand;
      animation-duration: .3s;
      animation-fill-mode: forwards;
    }
}

.timeline-prev-arrow {
  position: absolute;
  top: -5%;
  right: 15%;
}

.timeline-body-block-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  padding-top: 60px;
}


// awards

.about-awards {
  padding: 100px;
  background-color: $light-grey;
}

.about-awards-title {
  @include red-sub-header-text;
  padding-bottom: 25px;
}

.about-awards-body {
  border-top: 1px solid $cool-grey;
}

.about-awards-year-block {
  border-bottom: 1px solid $cool-grey;
  display: flex;
  flex-direction: row;
  gap: 15px;
  justify-content: space-between;
  padding: 25px 10px;
}

.about-awards-year {
  font-family: $font-3;
  font-size: 18px;
  letter-spacing: .15em;
  margin-bottom: 0px;
}

.about-awards-list {
  text-align: right;
  display: flex;
  flex-direction: column;
  gap: 25px;
  font-family: $font-1;
  font-size: 28px;
  p {
    margin-bottom: 0px;
  }
}

@media screen and (max-width: 760px) {

 

  // values

  .about-values-title {
    padding-left: 50px;
    font-size: 14px;
    margin-top: 50px;
  }

 
  .about-values-card-number {
    .about-values-card-num1 {
      font-size: 24px;
      margin: 0px;
    }
    .about-values-card-num2 {
      font-size: 14px;
      margin: 0px;
    }
  }

  .about {
    .card-white-body {
      max-width: none;
    }
  }


  // difference

  .about-differences {
    padding: 50px;
  }

  .about-difference-sub {
    font-size: 14px;
  }

  .about-difference-img {
    width: 50%;
    height: auto;
  }

  .about-header.card-white{
    gap:0px;
    .card-white-body{
      width: 100%;
      padding: 0px;
      max-width: none;
      margin-bottom: 25px;
    }
    .card-white-text{
      font-size: 14px !important;
    }
  }

  .about-img-1.card-white-img {
    width: 100%;
    align-self: normal;
    margin: 0px auto 50px;
    height: 300px;
  }

  .about .card-white-title {
    font-size: 48px;
  }

  .about .card-white-img {
    max-width: 700px !important;
  }

  .about.card-white {
    gap: 25px;
    max-width: 800px;
    .card-white-text{
      font-size: 14px !important;
    }
  }

  //  history
  

  .about-history-header-title {
    font-size: 14px;
  }

  .about-history-header-text {
    font-size: 28px;
  }


  //  awards

  .about-awards-title {
    font-size: 14px;
  }

  .about-awards {
    padding: 50px;
  }

  .about-awards-year-block {
    flex-direction: column;
  }

  .about-awards-list {
    text-align: left;
    font-size: 24px;
  }

  // timeline

  .timeline-img-container{
    flex-direction: column;
  }

  .timeline-img-container{
    padding: 0px;
  }

  .timeline-img-card{
    width:100%;
    font-size: 14px;
  }
  .timeline-body-blurb {
    font-size: 14px;
  }
  .timeline-carousel-img{
    max-height: 57vw;
  }

}


@media screen and (max-width: 1296px) {
  // about model

  .about-model-blurb {
    margin-bottom: 25px;
  }

  .about-model-blurb-title {
    font-size: 48px;
    line-height: 40px;
  }



  // timeline



    .timeline-carousel-container{
      padding:50px 100px;
    }


    .timeline-carousel-body{
      justify-content: space-between;
      min-height: auto;
    }

    .timeline-carousel-card{
      padding: 0px;
    }

    .timeline-carousel-body-block.image {
      display: none;
    }

    .timeline-img-container{
      padding-bottom: 0px;
    }
  
    .time-year-range-div{
      position: static;
      flex-grow: 1;
    }

  .timeline-header{
    display:flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 50px;
    width: 100%;
  }

    .timeline-year-range {
      position:static;
      transform: none;
      font-size: 36px;
      margin-bottom: 0px;
    
    }

    .timeline-next-arrow {
      position: static;
      top: 0px;
      right: 0px;
      padding-left: 25px;
    }
    
    .timeline-prev-arrow {
      position: static;
      top: 0px;
      right: 0px;
    }
    .timeline-next-arrow-icon, .timeline-prev-arrow-icon{
      margin:1.5px;
      &:hover{
        cursor: pointer;
          --size1:25px;
          --size2:28px;
          --margin1:1.5px;
          --margin2:0px;
          animation-name: arrowexpand;
          animation-duration: .3s;
          animation-fill-mode: forwards;
        }
    }
    .timeline-body-blurb {
      font-size: 14px;
    }

    .box2{
      flex:1.9;
    }

    }


@media screen and (max-width: 1120px) {

  // about history
  .about-history {
    padding: 50px 100px 0px;
 
  }

  // about model
  .about-model-title {
    font-size: 14px;
    margin-bottom: 50px;
  }

  .about-model {
    padding: 50px 100px;
  }

  .about-model-img {
    width: 100%;
    max-height: 600px;
    object-fit: cover;
    object-position: center 90%;
  }

  .about-model-body-text {
    width: 100%;
  }

  .about-model-body {
    flex-direction: column-reverse;
    gap: 50px;
  }

  .about-model-blurb {
    margin-bottom: 50px;
  }

  .about-model-blurb-title {
    font-size: 48px;
    margin-bottom: 25px;
    line-height: 50px;
  }

  .about-model-blurb-text {
    font-size: 14px;
  }
}

@media screen and (max-width: 992px) {


  .about-header.card-white-title {
    font-size: 48px;
  }
  .about{
    .card-white-img{
     max-width: 400px;
    }
  }
  .about{
    .card-white-title{
      font-size: 48px !important;

    }
  }
  
  .about-history {
    padding: 50px 100px 0px 100px;
    margin-top: 50px;
  }

  .about-history-header-text {
    font-size: 28px;
  }

  .timeline-carousel-container{
    padding:50px 100px;
  }

 .timeline-carousel-body{
   flex-direction: column;
   min-height: auto;
   gap:0px;
 }


  .timeline-carousel-card{
    padding: 0px;
  }

  .timeline-carousel-body-block{
    width:100%;
  }

  .timeline-carousel-body-block.image {
    display: block;
    order:2;
    img{
      padding-top:50px;
    }
  }

  .timeline-header{
    display:flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 50px;
  }

 
  .timeline-body-block-img {
    max-height: 500px;
  }

  .timeline-next-arrow {
    position: static;
    top: 0px;
    right: 0px;
    margin-left: 25px;
  }
  
  .timeline-prev-arrow {
    position: static;
    top: 0px;
    right: 0px;
  }
  .timeline-next-arrow-icon, .timeline-prev-arrow-icon{
    &:hover{
      animation-name: none;
    }
  }
  .about-model{
      padding: 50px;
  }
}

@media screen and (max-width: 950px) {
  .about-history {
    padding: 50px 50px 0px;
  }

  .timeline-carousel-container{
    padding:50px;
  }

   // created
  .about-created-box {
    width:139px;
    padding: 0px 25px;
  }

  .about-created {
    padding: 50px;
    margin-top: 50px !important;
    .about-created-box-text2 {
      font-size: 28px;
    }
  }

 .about-created-box-text1 {
  white-space: break-spaces !important;
  font-size: 12px;
  flex:1;
}


.about-created-box-text1,.about-created-box-text2 {
  font-size: 12px;
  padding-bottom: 15px;
  margin: 0px;
}

.about-created-boxes,
.about-created-group {
  border: none;
}

.about-created-box-div {
  border: none;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.about-created-title {
  font-size: 14px;
}

.about-created-group.group2 {
  padding-top: 25px;
}

.about-created-group.group1 {
  margin-bottom: 25px;
}

.box2,
.box4 {
  border-left: 1px solid $cool-grey;
  border-right: 1px solid $cool-grey;
  flex: 1;
}

.box1,
.box3 {
  border-left: 1px solid $cool-grey;
  flex: 1;
}

.about-created-boxes {
  flex-direction: column;
}

}


@media screen and (max-width:365px){
  .about-created-box-text1{
    font-size: 10px;
  }
  .about-created-box-text2{
    font-size: 22px !important;
  }
}