.capital-container {
  max-width: 2000px;
  margin: auto;
  min-height:100vh;
}

.capital-hero {
  padding: 50px;
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.capital-hero-sub {
  @include red-sub-header-text;
  font-size: 14px;
}

.capital-hero-title {
  font-family: $font-1;
  font-size: 28px;
  text-transform: capitalize;
  margin-bottom: 25px;
}

.dark-red-btn.investor-portal-btn {
  margin: 3px;
  width: fit-content !important;
}

.capital-block-1 {
  display: flex;
  flex-direction: row;
  gap:25px;
  overflow: hidden;
  justify-content: space-between;
  width: 100%;
  margin: auto;
  height: auto;
  overflow: hidden;
  .capital-block-1-img {
    width: 47%;
    height: auto;
    object-fit: cover
  }
}

.capital-offset-block {
  @include offset-words-block;
  padding: 50px;
  gap: 50px;
}

.capital-offset-text {
  @include offset-words-txt2;
  font-family: $font-1;
  font-size: 18px;
  margin-bottom: 0px;
  width: 100%;
  text-align: right;
}

.capital-offset-text1 {
  @include offset-words-txt1;
  width: 100%;
  margin-bottom: 0px;
  line-height: 2rem;
}

.capital-offset-text2 {
  @include offset-words-txt2;
  align-self: flex-start;
  text-align: left;
  font-size: 18px;
  width: 100%;
  margin-bottom: 0px;
}

.our-leaders {
  background-color: $light-grey;
  padding: 50px;
}

.our-leaders-title {
  @include red-sub-header-text;
  padding-bottom: 50px;
  font-size: 14px;
}

.leaders-card {
  display: flex;
  flex-direction: column;
  gap: 50px;
  padding-bottom: 50px;
}

.leaders-image {
  width: 100%;
  height: auto;
  object-fit: cover;
  max-width: none;
}

.leaders-name {
  font-family: $font-1;
  font-size: 36px;
  margin-bottom: 15px;
}

.leaders-title {
  font-family: $font-3;
  font-size: 14px;
  border: 1px solid black;
  padding: 8px 16px;
  width: fit-content;
  margin-bottom: 50px;
  text-transform: uppercase;
  letter-spacing: .15em;
}

.leaders-bio {
  font-family: $font-2;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 0px;
}

.our-team-btn {
  @include dark-red-btn;
  margin: 3px auto;
  width: 100%;
}

.capital-card-1 {
  display: flex;
  flex-direction: column-reverse;
  background-color: white;
  gap: 50px;
  padding: 50px;
}

.capital-card-2 {
  display: flex;
  flex-direction: column;
  gap: 50px;
  padding: 50px;
}

.capital-card-1-img,
.capital-card-2-img {
  width: 100%;
  height: auto;
  object-fit: cover;
  max-height: 500px;
}

.capital-card-1-text,
.capital-card-2-text {
  font-family: $font-2;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.capital-card-grey.cap-card-white {
  background-color: white !important;
}
.capital-card-grey {
  padding: 50px;
  .card-grey {
    padding: 50px;
  }
  .card-grey-body-title {
    font-size: 48px;
  }
  .card-grey-body-text {
    font-size: 14px;
    margin-bottom: 25px;
  }
  .card-grey-body {
    gap: 25px;
  }
  .card-grey-img {
    width: 100%;
    max-height: none;
    height: auto;
    margin: auto;
  }
  .card-grey-button{
    margin:auto;
  }
}

.capital-block-3-img,
.capital-block-2-img {
  width: 100%;
  height: auto;
}

.capital-block-2-img {
  padding: 0px 50px;
}

@media screen and (min-width: 760px) {
  .our-leaders {
    padding: 100px;
  }

  .our-leaders-title {
    padding-bottom: 100px;
    font-size: 18px;
  }

  .capital-hero {
    padding: 100px;
    gap: 25px;
  }

  .capital-hero-title {
    font-size: 64px;
  }

  .capital-hero-sub {
    font-size: 18px;
  }

  .leaders-card {
    flex-direction: row;
  }

  .leaders-body {
    width: 100%;
    margin: auto;
  }

  .leaders-image {
    max-height: 550px;
    width: 50%;
    max-width: 550px;
  }

  .our-team-btn {
    width: fit-content;
    max-width: 180px;
    margin: 3px auto;
    --padding1:16px 36px;
    --padding2:19px 39px;
    --margin1:3px auto;
    --margin2:0px auto;
    animation-duration: .3s;
    animation-name: leavebtn;
    animation-fill-mode: forwards;
    &:hover{
      animation-duration: .3s;
      animation-name: expandbtn;
      animation-fill-mode: forwards;
    }
  }

  .our-team-btn-div{
    display: flex;
    justify-content: center;
    align-content: center;
  }
  
  .capital-card-1,
  .capital-card-2 {
    flex-direction: row;
  }

  .capital-card-1-img,
  .capital-card-2-img {
    max-height: none;
    width: 50%;
  }

  .capital-card-1-text,
  .capital-card-2-text {
    font-size: 22px;
    margin: auto;
    line-height: 27px;
    gap: 25px;
  }

  .capital-card-1-text {
    text-align: right;
  }

  .capital-block-1 {
    gap: 50px;
    margin-bottom: 100px;
     .capital-block-1-img {
    width: 48%;
    height: auto;
  }
  }

  .capital-offset-block {
    gap: 100px;
    padding: 0px 100px 100px 100px;
  }

  .capital-offset-text {
    text-align: right;
    width: 93%;
    font-size: 36px;
  }

  .capital-offset-text1 {
    width: 90%;
    line-height: normal;
  }

  .capital-offset-text2 {
    align-self: flex-end;
    text-align: left;
    font-size: 22px;
    width: 70%;
  }

  .capital-block-2-img {
    padding: 0px 100px 100px 100px;
  }

  .capital-card-grey {
    gap: 100px !important;
    .card-grey {
      padding: 100px;
    }
    .card-grey-body-title {
      font-size: 84px;
    }
    .card-grey-img {
      width: 50%;
      max-height: none;
    }
    .card-grey-body-text {
      font-size: 16px;
    }
    .card-grey-button{
      margin:3px;
    }
  }
  .dark-red-btn.investor-portal-btn {
    --padding1:16px 36px;
    --padding2:19px 39px;
    --margin1:3px ;
    --margin2:0px ;
    animation-duration: .3s;
    animation-name: leavebtn;
    animation-fill-mode: forwards;
    &:hover{
      animation-duration: .3s;
      animation-name: expandbtn;
      animation-fill-mode: forwards;
    }
   
  }
  
}
@media screen and (min-width: 760px) and (max-width:992px){

  .capital-card-grey {
    gap: 50px !important;
    .card-grey {
     
      padding: 100px;
    }
    .card-grey-body-title {
      font-size: 48px;
    }
    .card-grey-body-text {
      font-size: 14px;
      margin-bottom: 25px;
    }
    .card-grey-body {
      gap: 25px;
    }
    .card-grey-img {
      width: 100%;
      max-height: 600px;
      height: auto;
      margin: auto;
    }
    .card-grey-button{
      margin:auto;
    }
  }
}
@media screen and (min-width: 1030px) {
  .capital-card-1,
  .capital-card-2 {
    gap: 100px;
    padding: 100px;
  }

  .capital-card-2 {
    padding-top: 0px;
  }

  .leaders-card {
    gap: 100px;
    padding-bottom: 100px;
    .leaders-name {
      font-size: 48px;
    }
    .leaders-title {
      font-size: 18px;
    }
    .leaders-bio {
      font-size: 22px;
      line-height: 27px;
    }
  }
  
  .capital-card-grey {
    gap: 100px !important;
    .card-grey {
      padding: 100px;
    }
    .card-grey-body-title {
      font-size: 84px;
    }
    .card-grey-img {
      width: 50%;
      max-height: none;
    }
  }
}
