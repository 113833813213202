.home-container {
  max-width: 2000px;
  margin: auto;
  min-height:100vh;
  border-bottom:1px solid $cool-grey;
}

.home-hero {
  padding: 100px;
}

.home-hero-title {
  font-family: $font-1;
  font-size: 78px;
  font-weight: 100;
  width: 80%;
  margin-bottom: 0px;
}

.home-blurb-1 {
  font-family: $fancy-font;
  font-size: 84px;
}

.home-offset-text-block {
  @include offset-words-block;
  gap: 50px;
}

.home-about-btn-div{
   width: fit-content;
   margin: auto;
}
.home-about-btn{
  --padding1:16px 36px;
  --padding2:19px 39px;
  --margin1:3px;
  --margin2:0px ;
  animation-duration: .3s;
  animation-name: leavebtn;
  animation-fill-mode: forwards;
  &:hover{
    animation-duration: .3s;
    animation-name: expandbtn;
    animation-fill-mode: forwards;
  }
}



.home-offset-txt {
  @include offset-words-txt2;
  align-self: flex-start;
  width: 100%;
  margin-bottom: 0px;
}

.home-offset-text-btn {
  @include dark-red-btn;
  margin: auto;
}

.home-block-1-img {
  width: 100%;
  height: auto;
  padding-right: 100px;
  object-fit: cover;
}

.home-card-1 {
  .card-white-body{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin:0px

  }
  .card-white-img {
    width: 50%;
    height: auto;
    margin: auto 0px;
  }
  .card-white-title {
    font-size: 54px;
  }
  .card-white-text{
    margin-bottom: 0px;
    margin-top:0px;
    padding-bottom: 25px;
  }
  .card-white-btn{
    margin:3px;
    --padding1:16px 36px;
    --padding2:19px 39px;
    --margin1:3px ;
    --margin2:0px ;
    animation-duration: .3s;
    animation-name: leavebtn;
    animation-fill-mode: forwards;
    &:hover{
      animation-duration: .3s;
      animation-name: expandbtn;
      animation-fill-mode: forwards;
    }
  }
}

.home-card-2 {
  .card-grey-body {
    width: 50%;
  }
  .card-grey-img {
    width: 50%;
    height: auto;
    margin: auto;
  }
  .card-grey-body-text {
    font-size: 22px !important;
    line-height: normal !important;
    margin-bottom: 0px;
  }
  .card-grey-body-title {
    font-size: 54px !important;
  }
  .card-grey-button{
    margin: 3px;
    --padding1:16px 36px;
    --padding2:19px 39px;
    --margin1:3px ;
    --margin2:0px ;
    animation-duration: .3s;
    animation-name: leavebtn;
    animation-fill-mode: forwards;
    &:hover{
      animation-duration: .3s;
      animation-name: expandbtn;
      animation-fill-mode: forwards;
    }
  }
}

.home-card-2.card-grey {
  gap: 100px !important;
}

.home-companies {
  padding: 50px;
}

.home-companies-subhead {
  @include red-sub-header-text;
  margin: 50px 0px;
}

.home-companies-text {
  font-family: $font-1;
  font-size: 36px;
  margin-bottom: 50px;
  width: 90%;
}

.home-companies-cards-div {
  display: flex;
  flex-direction: row;
  gap: 50px;
}

.home-companies-card {
  display: flex;
  flex-direction: column;
  // gap: 38.5px;
  width: 33%;
  img {
    width: 100%;
    height: auto;
    margin-bottom: 38.5px;
    &:hover {
      --marBotStart:38.5px;
      --marBotEnd:32px;
      animation-name: cardexpand;
      animation-duration: .3s;
      animation-fill-mode: forwards;
    }
  }
  p {
    font-family: $font-3;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: .15em;
  }
}

.home-companies-card-body {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.home-collection-title {
  @include red-sub-header-text;
  font-family: $font-2 !important;
  font-size: 18px !important;
  text-align: left !important;
}

.home-container {
  .contact-us-collection-container {
    padding: 100px;
  }
}

.home-more-info {
  margin: auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 25px;
  padding: 100px;
  margin-bottom: 0px;
  align-items: center;
}

.home-more-info-title {
  font-size: 48px;
  font-family: $font-1;
}

.home-more-info-text {
  font-size: 22px;
  font-family: $font-2;
  margin-bottom: 0px;
  line-height: normal;
  padding-bottom: 25px;
  width: 75%;
  margin: auto;
}

.home-more-info-btn {
  @include dark-red-btn;
  margin: 3px auto;
  --padding1:16px 36px;
  --padding2:19px 39px;
  --margin1:3px auto;
  --margin2:0px auto;
  animation-duration: .3s;
  animation-name: leavebtn;
  animation-fill-mode: forwards;
  &:hover{
    animation-duration: .3s;
    animation-name: expandbtn;
    animation-fill-mode: forwards;
  }
  
}

.home-page-testimonials {
  .testimonial-quote {
    font-size: 48px !important;
  }
}

.home-divider{
  width:100%;
  height: 1px;
  border-top:1px solid $cool-grey;
}

// ipad
@media screen and (max-width: 1080px) {
  .home-hero-title {
    width: 100%;
    margin: 0px;
  }

  .home-container {
    .contact-us-collection-container {
      padding: 50px;
    }
  }
}

// mobile styling
@media screen and (max-width: 992px) {
  .home-hero-title {
    font-size: 52px;
  }

  .home-card-1 {
    .card-white-title {
      font-size: 46px;
      margin-bottom: 0px;
      margin-top: 0px;
      white-space: nowrap;
    }
    .card-white-body {
      gap: 25px !important;
      width: 100% !important;
      margin: auto !important;
      max-width: 500px !important;
    }
    .card-white-body-title {
      font-size: 48px;
    }
    .card-white-text {
      font-size: 14px !important;
    }
    .card-white-img {
      width: 100%;
      height: auto;
      max-width: 500px;
      margin: auto;
      object-fit: cover;
      object-position: bottom center;
    }
    .card-white-btn {
      width: 100% !important;
      white-space: nowrap;
      max-width: 400px !important;
      // margin: auto !important;
    }
  }

  .home-card-2.card-grey {
    gap: 50px !important;
  }

  .home-card-1.card-white {
    gap: 50px !important;
  }

  .home-card-2 {
    .card-grey-body {
      gap: 25px !important;
      width: 100% !important;
      margin: auto !important;
      max-width: 500px !important;
    }
    .card-grey-body-title {
      font-size: 48px !important;
      white-space: nowrap;
      margin-bottom: 0px;
    }
    .card-grey-body-text {
      font-size: 14px !important;
    }
    .card-grey-img {
      width: 100%;
      height: auto;
      max-width: 500px;
      object-fit: cover;
      object-position: bottom center;
    }
    .card-grey-button {
      width: fit-content;
      margin:auto;
      white-space: nowrap;
    }
  }

  .home-page-testimonials {
    .testimonial-quote {
      font-size: 28px !important;
    }
  }

.home-companies-cards-div {
  gap: 25px;
}

}

@media screen and (max-width: 760px) {
  .home-hero {
    padding: 50px;
  }

  .home-hero-title {
    font-size: 28px;
  }

  .home-blurb-1 {
    font-size: 48px;
    line-height: 60px;
  }

  .home-offset-text-block {
    padding: 50px;
    gap: 50px;
  }

  .home-offset-txt {
    font-size: 18px;
    text-align: left;
    align-self: flex-start;
    width: 100%;
    line-height: normal;
  }

  .home-companies-text {
    font-size: 28px;
    width: 100%;
  }

  .home-companies-subhead {
    font-size: 14px;
    margin-top: 0px;
  }

  .home-companies-card {
    width: 100%;
    img {
      &:hover {
        animation-name:none;
        margin-bottom: 38.5px;
      }
    }
    p {
      &:hover{
        animation-name: none;
      }
    }
   
  }

  .home-companies-cards-div {
    flex-direction: column;
    gap:50px;
  }

  .home-companies-card-body {
    font-size: 14px;
    letter-spacing: 0.15em;
  }

  .home-more-info-title {
    font-size: 36px;
  }

  .home-more-info-text {
    font-size: 18px;
    line-height: 21px;
    padding-bottom: 25px;
    width: 100%;
    margin: auto;
  }

  .home-more-info {
    padding: 50px;
  }

  .home-collection-title {
    font-size: 14px !important;
  }
  
  .home-block-1-img {
    padding-right: 50px;
  }

  .home-about-btn-div{
    width: 100%;
 }
}


@media screen and (max-width:376px){
  .home-card-2 {
    .card-grey-body-title {
      white-space:normal;
    }

  }
  .home-card-1 {
    .card-white-title {
      white-space: normal;
    }
  }
}