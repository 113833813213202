.footer-container {
  padding: 50px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: auto;
  max-width: 2000px;
  margin: auto;
}

.galerie-logo {
  width: 145.68px;
  height: 35.05px;
  margin-bottom: 20px;
}

.footer-header-div {
  width: 100%;
  text-align: center;
}

.footer-div {
  width: 100%;
}

.footer-line {
  height: 1px;
  background-color: $grey-1;
  margin-bottom: 25px;
  align-self: stretch;
}

.footer-column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.footer-description {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.footer-address,
.footer-link {
  font-family: $font-3;
  font-size: 14px;
  letter-spacing: 0.7px;
  line-height: normal;
  text-transform: uppercase;
  color: $black;
  text-decoration: none !important;
  margin-bottom: 25px;
  &:hover {
    color: $dark-red !important;
    cursor: pointer;
  }
}

.footer-header {
  font-family: $font-1;
  font-size: 28px;
  line-height: normal;
  text-transform: capitalize;
  color: $black;
  text-decoration: none !important;
  margin-bottom: 15px;
  &:hover{
    color: $dark-red !important;
  }
}

.footer-red-line {
  width: 100%;
  height: 1px;
  border: 0.9px solid $dark-red;
}

.footer-phone-number {
  font-family: $font-3;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  text-decoration: none !important;
  text-transform: uppercase;
  color: $black;
  margin-bottom: 25px;
  letter-spacing: .05em;
}

a.footer-email {
  font-family: $font-3;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  text-decoration: none !important;
  color: $black;
  text-transform: uppercase;
  text-decoration: underline;
  &:hover {
    color: $dark-red !important;
    cursor: pointer;
  }
}

.copyright {
  padding: 14px 0 0;
  display: flex;
  flex-direction: column;
  justify-content: normal;
  align-items: flex-start;
  padding: 0px;
  gap: 0px;
  border-top: 1px solid $grey-1;
  margin-top: 0px;
  a {
    color: $grey-1;
  }
}

.copyright-description,
.copyright-fha-disclaimer {
  margin-top: 15px;
  font-family: $font-2;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  color: $grey-1;
  p{
    margin-bottom: 0px;
  }
}
.copyright-description
.copyright-fha-disclaimer {
  width: 100%;
}

.copyright-description {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.privacy-policy-link{
  &:hover{
    cursor: pointer;
    text-decoration: underline;
  }
}

.privacy-policy-body{
  padding:15px 25px;
}

.privacy-policy-container{
  position: fixed;
  z-index: 999;
  border:1px solid grey;
  top:0;
  left:0;
  right:0;
  width:90%;
  height:92%;
  overflow: scroll;
  margin:15px auto;
  background-color: white;
  padding: 10px;
}

@media screen and (min-width: 1188px) {
  .footer-container {
    padding: 100px;
    width: 100%;
  }

  .footer-columns-div {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    gap: 20px;
  }

  .footer-column {
    max-width: 800px;
  }

  .footer-div {
    display: flex;
    flex-direction: row;
    gap: 50px;
    justify-content: space-between;
  }

  .footer-header-div {
    align-self: flex-start;
    flex-direction: column;
    width: 127.87px;
    margin: 0px;
    justify-content: normal;
    gap: 0px;
  }

  .footer-address,
  .footer-link,
  .footer-title,
  .footer-phone-number {
    margin-bottom: 25px;
  }

  .footer-red-line {
    margin-bottom: 25px;
  }

  .copyright-fha-disclaimer {
    width: 70%;
  }

  .copyright-description {
    width: 255px;
  }
  
  .copyright {
    flex-direction: row;
    justify-content: space-between;
    padding: 0px;
    gap: 25px;
    margin-top: 25px;
  }

  .privacy-policy-body{
    padding:35px 50px;
  }
  
  .privacy-policy-container{
    margin:35px auto;
    padding: 50px;
  }
}
