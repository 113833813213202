
.collections-container {
    max-width: 2000px;
    margin: auto;
    min-height: 100vh;
    border-bottom:1px solid $cool-grey;
}

.collections-hero {
    padding: 50px;
    margin: 0px auto;
    max-width: fit-content;
    width: 390px;
    display: flex;
    flex-direction: column;
    gap: 25px;
}

.collections-header-img {
    width: 100%;
    height: auto;
    object-fit: cover;
}

.collections-header-title {
    font-family: $font-1;
    font-weight: 400;
    font-size: 36px;
    text-transform: uppercase;
    color: $off-black;
    margin-bottom: 0px;
}

.collections-header-text {
    font-family: $font-2;
    font-size: 14px;
    color: $off-black;
    margin-bottom: 0px;
}

.collections-offset-words-block {
    @include offset-words-block;
    padding: 50px;
    gap: 50px;
}

.collections-offset-words-txt1 {
    @include offset-words-txt1;
    font-size: 28px;
    width: 99%;
    line-height: 30px;
    margin-bottom: 0px;
}

.collections-offset-words-txt2 {
    @include offset-words-txt2;
    font-size: 18px;
    width: 99%;
    line-height: normal;
    margin-bottom: 0px;
}

.collections-card {
    gap:50px;
    .card-white-title {
        font-size: 48px;
    }
    .card-white-img {
        width: 100%;
        height: auto;
        margin: auto;
    }
    .card-white-body {
        width: 100%;
        margin: auto;
        max-width: none;
        display: flex;
        flex-direction: column;
        max-width: 500px !important;
    }
    .card-white-text {
        margin-bottom: 0px !important;
        padding-bottom: 50px;
    }
    .card-white-btn {
        margin: 3px;
        --padding1:16px 36px;
        --padding2:19px 39px;
        --margin1:3px ;
        --margin2:0px ;
        animation-duration: .3s;
        animation-name: leavebtn;
        animation-fill-mode: forwards;
        &:hover{
          animation-duration: .3s;
          animation-name: expandbtn;
          animation-fill-mode: forwards;
        }
    }
}

// desktop size
@media screen and (min-width: 760px) {
    
    .collections-card {
        gap:100px;
        margin: auto;
        .card-white-title {
            font-size: 84px;
        }
        .card-white-img {
            width: 45%;
            height: auto;
            margin: 0px;
            max-width: 500px;
        }
        .card-white-body {
            width: 55%;
            margin: auto 0px;
            max-width: none !important;
            
        }
    }

    .collections-offset-words-block {
        padding: 100px;
        gap:100px;
    }

    .collections-offset-words-txt1 {
        font-size: 28px;
        width: 80%;
    }

    .collections-offset-words-txt2 {
        font-size: 22px;
        width: 80%;
        line-height: 28px;
    }

    .collections-header-img {
        max-height: 100vh;
    }

    .collections-hero {
        padding: 100px;
        margin: 0px;
        max-width: none;
        width: 100%;
    }

    .collections-header-title {
        font-size: 78px;
    }

    .collections-header-text {
        font-size: 22px;
    }
}
@media screen and (min-width: 760px) and (max-width: 1200px) {
    .collections-card {
        padding: 75px !important;
        gap: 50px !important;
        .card-white-img {
            width: 100%;
            height: auto;
            margin: auto;
        }
        .card-white-body {
            width: 100%;
            margin: auto;
            max-width: none;
            max-width: 500px !important;
        }
    }
}
